import React, { useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

export default function NewLineChart({ dataSet = [], usersDataSet = [], dataSet3, detail1 = {}, detail2 = {}, detail3 = {}, chartWidth = "100%", division = "daily", chartId = "chartdiv" }) {
    useEffect(() => {
        let chart = am4core.create(chartId, am4charts.XYChart);
        chart.padding(40, 40, 40, 40);
        chart.logo.disabled = true;
        // console.log(dataSet);

        function transformXLabels(value, target) {
            const date = dayjs(target.dataItem.category);
            let x;
            switch (division) {
                case "daily": {
                    x = date.format("DD-MM-YYYY");
                    break;
                }
                case "weekly": {
                    x = formatWeekRange(target.dataItem.category);
                    // x = target.dataItem.category;
                    break;
                }
                case "monthly": {
                    x = date.format("MMMM YYYY");
                    break;
                }
                case "yearly": {
                    x = date.format("YYYY");
                    break;
                }
            }
            return x;
        }

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.data = dataSet;
        categoryAxis.dataFields.category = "_id";
        categoryAxis.renderer.grid.template.location = 0; // Adjust grid lines
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.labels.template.maxWidth = 100;
        categoryAxis.renderer.labels.template.wrap = true;
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.fontSize = 12;
        categoryAxis.renderer.labels.template.adapter.add("text", transformXLabels);
        // if (!dataSet) {
        //     categoryAxis.renderer.labels.template.disabled = true;
        // } else {
        //     categoryAxis.renderer.labels.template.disabled = false;
        //     categoryAxis.renderer.labels.template.adapter.add("text", transformXLabels);
        // }

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.fontSize = 12;
        yAxis.tooltip.disabled = true;
        yAxis.min = 0;

        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.data = dataSet;
        series1.dataFields.categoryX = "_id";
        series1.dataFields.valueY = detail1.valueY;
        series1.strokeWidth = 2;
        series1.name = detail1.name;
        series1.tooltipText = detail1.tooltipText;
        let s1Bullet = new am4charts.CircleBullet();
        s1Bullet.circle.stroke = am4core.color(detail1.color);
        s1Bullet.circle.fill = am4core.color(detail1.color);
        series1.bullets.push(s1Bullet);
        series1.stroke = am4core.color(detail1.color);
        series1.tensionX = 0.8;
        series1.tensionY = 0.8;
        series1.fillOpacity = 1;
        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        fillModifier.gradient.addColor(am4core.color(detail1.color), 0.3, 0);
        fillModifier.gradient.addColor(am4core.color("#ffffff"), 0.5, 1);
        series1.fill = fillModifier.gradient;

        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.data = usersDataSet;
        series2.dataFields.categoryX = "_id";
        series2.dataFields.valueY = detail2.valueY;
        series2.strokeWidth = 2;
        series2.name = detail2.name;
        series2.tooltipText = detail2.tooltipText;
        let primaryColor = getComputedStyle(document.documentElement).getPropertyValue("--primary-color").trim();
        let s2Bullet = new am4charts.CircleBullet();
        s2Bullet.circle.stroke = am4core.color(primaryColor);
        s2Bullet.circle.fill = am4core.color(primaryColor);
        series2.bullets.push(s2Bullet);
        series2.stroke = am4core.color(primaryColor);
        series2.tensionX = 0.8;
        series2.tensionY = 0.8;
        series2.fillOpacity = 1;
        let fillModifier2 = new am4core.LinearGradientModifier();
        fillModifier2.opacities = [1, 0];
        fillModifier2.offsets = [0, 1];
        fillModifier2.gradient.rotation = 90;
        fillModifier2.gradient.addColor(am4core.color(primaryColor), 0.3, 0);
        fillModifier2.gradient.addColor(am4core.color("#ffffff"), 0.5, 1);
        series2.fill = fillModifier2.gradient;

        if (Object.keys(detail3).length > 0 ) {
            let series3 = chart.series.push(new am4charts.LineSeries());
            series3.data = dataSet3;
            series3.dataFields.categoryX = "_id";
            series3.dataFields.valueY = detail3.valueY;
            series3.strokeWidth = 2;
            series3.name = detail3.name;
            series3.tooltipText = detail3.tooltipText;
            let s3Bullet = new am4charts.CircleBullet();
            s3Bullet.circle.stroke = am4core.color("#ff0000");
            s3Bullet.circle.fill = am4core.color("#ff0000");
            series3.bullets.push(s3Bullet);
            series3.stroke = am4core.color("#ff0000");
            series3.tensionX = 0.8;
            series3.tensionY = 0.8;
            series3.fillOpacity = 1;
            let fillModifier3 = new am4core.LinearGradientModifier();
            fillModifier3.opacities = [1, 0];
            fillModifier3.offsets = [0, 1];
            fillModifier3.gradient.rotation = 90;
            fillModifier3.gradient.addColor(am4core.color("#ff0000"), 0.3, 0);
            fillModifier3.gradient.addColor(am4core.color("#ffffff"), 0.5, 1);
            series3.fill = fillModifier3.gradient;
        }

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.bottomAxesContainer;
        chart.scrollbarX.align = "center";

        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.scrollable = true;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomXY";

		return () => {
			if (chart) {
				chart.dispose();
			}
		};
	}, [dataSet, usersDataSet, dataSet3, division, chartId]);

    return <div id={chartId} style={{ width: chartWidth, height: "500px" }}></div>;
}

function formatWeekRange(weekString) {
    if (!weekString) return;
    const [year, week] = weekString.split("-");
    const startOfWeek = dayjs().year(year).isoWeek(week).startOf("isoWeek"); // Get the start date of the week
    const endOfWeek = startOfWeek.endOf("isoWeek"); // Get the end date of the week
    return `${startOfWeek.format("DD MMM")} - ${endOfWeek.format("DD MMM YYYY")}`;
}

// GoogleMap.js
import React, { useRef, useEffect, useState } from 'react';
import { formatDate } from '../../services/functions';

const factoryBoundary = [
  { lat: 28.469767, lng: 77.587985 },
  { lat: 28.468192, lng: 77.584813 },
  { lat: 28.464561, lng: 77.587080},
  { lat: 28.466260, lng: 77.590296},
  // { lat: 28.525453, lng: 77.275376 },
];


const GoogleMapPreview = ({ markerPosition, tripCheckpoints }) => {
  const mapRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(true);
  

  console.log(' markerPosition',  markerPosition);

  useEffect(() => {
    if (!window.google || !mapRef.current) {
      setIsMapLoaded(false);
      return;
    }

    try{
      const map = new window.google.maps.Map(mapRef.current, {
        center: markerPosition,
        zoom: 17,
      });
  
      const marker = new window.google.maps.Marker({
        position: markerPosition,
        map: map,
      });
  
      // Example: Draw a polygon representing the factory layout
  
      const factoryPolygon = new google.maps.Polygon({
        paths: factoryBoundary,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
  
      factoryPolygon.setMap(map);
  
  
      // Create markers for each checkpoint
      tripCheckpoints.forEach((cp) => {
        console.log("cp====>>>", cp.details.vehicleNumber);
        // Create the circle icon marker
        const circleMarker = new google.maps.Marker({
          position: cp.position,
          map: map,
          title: cp.name,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#FF0000", // Fill color of the circle
            fillOpacity: 1,
            scale: 10, // Size of the circle
            strokeColor: "#FFFFFF", // Stroke color of the circle
            strokeWeight: 2, // Stroke width of the circle
          },
        });
  
        // If it's a truck checkpoint, create a truck icon next to the circle
        if (cp.lastVisited) {
          const offsetPosition = {
            lat: cp.position.lat, // Keep the same latitude
            lng: cp.position.lng + 0.0001, // Slightly offset longitude for side-by-side icons
          };
  
          const truckMarker = new google.maps.Marker({
            position: offsetPosition,
            map: map,
            title: `${cp.name} - Truck`,
            icon: {
              url: "/delivery.png", // Example truck icon URL
              scaledSize: new google.maps.Size(30, 30), // Adjust size of the truck icon
            },
          });
  
          // Add a combined info window for both icons
          const infoWindow = new google.maps.InfoWindow({
            content: `<div style="font-family: Arial, sans-serif; font-size: 14px;">
            <h4 style="margin: 0 0 10px;">${cp.name}</h4>
            <div style="margin-bottom: 5px;">
                 <strong>Vehicle Number:</strong> ${cp.details.vehicleNumber}
            </div>
            <div style="margin-bottom: 5px;">
                 <strong>In Time:</strong> ${formatDate(cp.details.inTime)}
            </div>
            <div>
                 <strong>Out Time:</strong> ${formatDate(cp.details.outTime)}
            </div>
          </div>`,
          });
  
  
          circleMarker.addListener("click", () => {
            infoWindow.open(map, circleMarker);
          });
  
          truckMarker.addListener("click", () => {
            infoWindow.open(map, truckMarker);
          });
        } else {
          // Add info window for circle icon only
          const infoWindow = new google.maps.InfoWindow({
            content: `<div style="font-family: Arial, sans-serif; font-size: 14px;">
            <h4 style="margin: 0 0 10px;">${cp.name}</h4>
            <div style="margin-bottom: 5px;">
                 <strong>Vehicle Number:</strong> ${cp.details.vehicleNumber}
            </div>
            <div style="margin-bottom: 5px;">
                 <strong>In Time:</strong> ${formatDate(cp.details.inTime)}
            </div>
            <div>
                 <strong>Out Time:</strong> ${formatDate(cp.details.outTime)}
            </div>
          </div>`,
          });
  
          circleMarker.addListener("click", () => {
            infoWindow.open(map, circleMarker);
          });
        }
      });
  
      // Draw connecting arrows between checkpoints
      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 3,
      };
  
      const paths = []
      if(tripCheckpoints){
        tripCheckpoints.forEach((el, index)=> {
          console.log('el', el, index)
          if(index < tripCheckpoints.length){
              paths.push({
                path: [el.position, tripCheckpoints[index+1]?.position],
                pathcolor: el.visited && tripCheckpoints[index+1]?.visited ? "green" : "red"
              }) 
          }
        })
      }
  
      paths.forEach((path) => {
        const line = new google.maps.Polyline({
          path: path.path,
          icons: [
            {
              icon: lineSymbol,
              offset: "100%",
            },
          ],
          strokeColor: path.pathcolor,
          strokeOpacity: 0.6,
          strokeWeight: 2,
        });
  
        line.setMap(map);
      });

    }catch(error){
      console.error("Error loading Google Map:", error);
      setIsMapLoaded(false);
    }
  
  }, [markerPosition, tripCheckpoints]);

  return isMapLoaded ? (
    <div
      ref={mapRef}
      style={{ width: "100%", height: "500px" }}
    />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
        backgroundColor: "#f0f0f0",
        color: "#666",
        fontSize: "18px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      No Map Available
    </div>
  );
};

export default GoogleMapPreview;
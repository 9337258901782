import React, { useState, useRef, useEffect } from "react";
import "./Analytics.css";
import { Button, DatePicker, DatePickerOfMonth, Dropdown } from "../utils";
import { Loader } from "../utils/index";
import dayjs from "dayjs";
import { buttonClasses } from "../utils/theme";
import { Grid } from "@mui/material";
import { useGetAnalyticsStatsMutation } from "../../api/api";
import { useOrgs, useUserDetail } from "../../services/hooks";
import NewLineChart from "../Dashboard/NewLineChart";
import { TripCreated, TripCompleted, TripTerminated } from "../utils/Genericstats";
import { TrendingUpIcon, TrendingDownIcon } from "../../services/icons";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
am4core.useTheme(am4themes_animated);

const DEFAULT_COMPANY_STATE = {
	id: "",
	name: "",
	status: "Activated",
	activeFrom: "",
};

const Analytics = () => {
	const [_company, setCompany] = useState(DEFAULT_COMPANY_STATE);
	const [filter, setFilter] = useState({ division: "monthly" });
	const [data, setData] = useState();
	const [graphData1, setGraphData1] = useState([]);
	const [graphData2, setGraphData2] = useState([]);
	const [loading, setLoading] = useState(false);
	const { orgOptions, orgs } = useOrgs();
	const { userDetail } = useUserDetail();
	const filterRef = useRef();
	const durationDdOption = [
		{ label: "Monthly", value: "monthly" },
		{ label: "Weekly", value: "weekly" },
	];
	const [getAnalyticsStats, { data: usersData }] = useGetAnalyticsStatsMutation();
	const startDate = dayjs().startOf("month");
	const endDate = startDate.endOf("month");
	// const DEFAULT_TRIP_FILTER = {
	// 	firstRange: {
	// 		start: startDate.subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
	// 		end: startDate.subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
	// 	},
	// 	secondRange: {
	// 		start: startDate.format("YYYY-MM-DD"),
	// 		end: endDate.format("YYYY-MM-DD"),
	// 	},
	// };

	useEffect(() => {
		const startDate = dayjs().startOf("month");
		const endDate = startDate.endOf("month");
		filterRef.current = {
			firstRange: {
				start: startDate.subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
				end: startDate.subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
			},
			secondRange: {
				start: startDate.format("YYYY-MM-DD"),
				end: endDate.format("YYYY-MM-DD"),
			},
			orgId: userDetail.userType !== "superAdmin" ? userDetail.orgId : _company?.id ? _company.id : "",
		};

		fetchStatus(filterRef.current);
	}, []);

	useEffect(() => {
		setFilter((prev) => ({
			...prev,
			start: null,
			end: null,
		}));
		setData(null);
	}, [filter.division]);

	function onChangeOrganization(value) {
		if (!value) {
			setCompany(DEFAULT_COMPANY_STATE);
			// fetchStatus(DEFAULT_TRIP_FILTER);
			return;
		}
		const orgInfo = orgs.find((_) => _._id === value);
		setCompany({
			...DEFAULT_COMPANY_STATE,
			id: orgInfo._id || "",
			name: orgInfo.name || "",
			status: orgInfo.status || "",
			activeFrom: orgInfo.addedOn || "",
		});
		// setFilter({ division: "daily" });
		// fetchStatus({
		// 	...DEFAULT_TRIP_FILTER,
		// 	orgId: value,
		// });
	}

	const fetchStatus = (filter) => {
		setLoading(true);
		getAnalyticsStats(filter)
			.unwrap()
			.then((res) => {
				setData(res);
				if (res.firstRangeData) {
					let res1 = validateHasAllDay(res.firstRangeData.graphData ||[], filter.firstRange.start, filter.firstRange.end, ["total", "completed", "terminated"]);
					setGraphData1(res1 || []);
				} 
				// else {
				// 	setGraphData1([]);
				// }
				if (res.secondRangeData) {
					let res2 = validateHasAllDay(res.secondRangeData.graphData||[], filter.secondRange.start, filter.secondRange.end, ["total", "completed", "terminated"]);
					setGraphData2(res2 || []);
				} 
				// else {
				// 	setGraphData2(null);
				// }
			})
			.catch((err) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (value, key) => {
		setFilter((previous) => {
			return { ...previous, [key]: value === null ? "" : value };
		});
	};

	const handleSubmit = () => {
		if (Object.keys(filter)?.length > 0) {
			if (filter?.start || filter?.end) {
				const startDate = dayjs(filter?.start);
				const endDate = dayjs(filter?.end);

				if (filter?.division === "monthly") {
					// For monthly division: Set start to 1st of the month, end to the last day of the month
					const firstRangeStart = startDate.startOf("month").format("YYYY-MM-DD");
					const firstRangeEnd = startDate.endOf("month").format("YYYY-MM-DD");
					const secondRangeStart = endDate.startOf("month").format("YYYY-MM-DD");
					const secondRangeEnd = endDate.endOf("month").format("YYYY-MM-DD");

					filterRef.current = {
						firstRange: {
							start: firstRangeStart,
							end: firstRangeEnd,
						},
						secondRange: {
							start: secondRangeStart,
							end: secondRangeEnd,
						},
						orgId: userDetail.userType !== "superAdmin" ? userDetail.orgId : _company?.id ? _company.id : "",
					};
				} else if (filter?.division === "weekly") {
					const firstRangeStart = startDate.format("YYYY-MM-DD");
					const firstRangeEnd = startDate.add(6, "days").format("YYYY-MM-DD"); // 7 days after the selected start date

					const secondRangeStart = endDate.format("YYYY-MM-DD");
					const secondRangeEnd = endDate.add(6, "days").format("YYYY-MM-DD");

					filterRef.current = {
						firstRange: {
							start: firstRangeStart,
							end: firstRangeEnd,
						},
						secondRange: {
							start: secondRangeStart,
							end: secondRangeEnd,
						},
						orgId: userDetail.userType !== "superAdmin" ? userDetail.orgId : _company?.id ? _company.id : "",
					};
				}
			}
		}
		fetchStatus(filterRef.current);
	};

	const validateHasAllDay = (data, start, end, keyArr) => {
		const generateDateRange = (startDate, endDate) => {
			const start = new Date(startDate);
			const end = new Date(endDate);
			const dates = [];
			while (start <= end) {
				dates.push(start.toISOString().split("T")[0]); // Format as YYYY-MM-DD
				start.setDate(start.getDate() + 1); // Increment by 1 day
			}
			return dates;
		};
		const allDates = generateDateRange(start, end);
		const dataMap = data.reduce((map, item) => {
			map[item._id] = { ...item };
			return map;
		}, {});
		const result = allDates.map((date) => {
			let res = {
				_id: date,
			};
			for (let key of keyArr) {
				res[key] = dataMap[date]?.[key] || 0;
			}
			return res;
		});
		// console.log("result", result);
		return result;
	};

	const series1 = {
		name: "Trip Created",
		tooltipText: "Trips Created: {valueY}",
		color: "#228b22",
		valueY: "total",
	};

	const series2 = {
		name: "Trip Completed",
		tooltipText: "Trip Completed: {valueY}",
		valueY: "completed",
	};

	const series3 = {
		name: "Trip terminated",
		tooltipText: "Trip Terminated:{valueY}",
		color: "#ff7200",
		valueY: "terminated",
	};

	const convertToDateMonthYear = (date) => {
		return dayjs(date).format("DD-MMM-YY");
	};
	const startDuration = convertToDateMonthYear(filterRef?.current?.firstRange?.start);
	const secondDuration = convertToDateMonthYear(filterRef?.current?.secondRange?.start);
	const firstEndDuration = convertToDateMonthYear(filterRef?.current?.firstRange?.end);
	const secondEndDuration = convertToDateMonthYear(filterRef?.current?.secondRange?.end);
	const calculateAvg = (tripCount, days) => {
		if (tripCount && days) {
			let avg = (tripCount / days).toFixed(2);
			return avg;
		} else {
			return 0;
		}
	};

	const calcualtePercentage = (valueOfA, valueOfB=0) => {
		const result = Math.abs(((valueOfA - valueOfB) / valueOfA) * 100).toFixed(2);
		return result;
	};

	const calculateDays = (date) => {
		return dayjs(date).daysInMonth();
	};
	const numberOfFirstDays = filter.division === "monthly" ? calculateDays(filterRef?.current?.firstRange?.start) : 7;
	const numberOfSecondDays = filter.division === "monthly" ? calculateDays(filterRef?.current?.secondRange?.start) : 7;

	return (
		<>
			<div className="content">
				<h1 className="title">Analytics (Monthly / Weekly Comparison)</h1>
				{userDetail.userType === "superAdmin" ? (
					<div className="company-filter">
						<Dropdown
							name="company"
							label="Company"
							options={orgOptions}
							value={orgOptions.find((_) => _.value == _company.id) || null}
							onChange={(op) => onChangeOrganization(op?.value)}
							dataTestId="companyFilter"
						/>
					</div>
				) : (
					<></>
				)}
			</div>
			<hr className="bgg" />
			<div className="contentpanelHeading">
				<Grid container spacing={"1em"} sx={{ justifyContent: "center" }}>
					<Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
						<Dropdown
							name="duration"
							label="Select"
							options={durationDdOption}
							value={durationDdOption.find((_) => _.value == filter?.division)}
							onChange={(op) => setFilter((old) => ({ ...old, division: op?.value || 'monthly', start: null, end: null }))}
						/>
					</Grid>
					{filter?.division === "monthly" ? (
						<>
							<Grid item xl={2} lg={3} md={3} sm={3} xs={12}>
								<DatePickerOfMonth
									name={"start"}
									view={["month", "year"]}
									minDATE={dayjs("2023-10-01")}
									maxDATE={dayjs()}
									value={filter?.start || null}
									required
									onChange={(val) => handleChange(val?.toISOString(), "start")}
									label={"Select Month"}
									views={["year", "month"]}
								/>
							</Grid>
							<Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
								<div className="vs">vs</div>
							</Grid>
							<Grid item xl={2} lg={3} md={3} sm={3} xs={12}>
								<DatePickerOfMonth
									name={"end"}
									minDATE={dayjs("2023-10-01")}
									maxDATE={dayjs()}
									value={filter?.end || null}
									required
									onChange={(val) => handleChange(val.toISOString(), "end")}
									label="Select Month"
									views={["year", "month"]}
								/>
							</Grid>
						</>
					) : (
						<>
							<Grid item xl={2} lg={3} md={3} sm={3} xs={12}>
								<DatePicker
									name={"start"}
									minDATE={dayjs("2023-10-01")}
									maxDATE={dayjs()}
									value={filter?.start ? dayjs(filter?.start) : null}
									required
									onChange={(val) => handleChange(val.toISOString(), "start")}
									label="Select Week Start Date"
								/>
							</Grid>
							<Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
								<div className="vs">vs</div>
							</Grid>
							<Grid item xl={2} lg={3} md={3} sm={3} xs={12}>
								<DatePicker
									name={"end"}
									minDATE={dayjs("2023-10-01")}
									maxDATE={dayjs()}
									value={filter?.end ? dayjs(filter?.end) : null}
									required
									onChange={(val) => handleChange(val.toISOString(), "end")}
									label="Select Week Start Date"
								/>
							</Grid>
						</>
					)}

					<Grid item xl={2} lg={2} md={3} sm={3} xs={12}>
						<Button testId={"Generate"} text={"Generate"} style={buttonClasses.outlined} disabled={!filter?.start || !filter?.end} onClick={() => handleSubmit()} />
					</Grid>
				</Grid>
				{/* <div>
					<Button text={""} style={buttonClasses.outlined} onClick={() => console.log("button clicked")}>
						<FileDownloadIcon />
						Download
					</Button>
				</div> */}
			</div>
			{loading ? (
				<Loader size="2rem" />
			) : (
				<>
					{data && (
						<>
							<div className="chartdetails">
								<div className="leftDetail">
									<div className="duration">
										{filter.division === "monthly" ? (
											<>
												<div className="time"> {startDuration} </div> To <div className="time"> {firstEndDuration} </div>
											</>
										) : (
											<>
												<div className="time">{startDuration} </div> To <div className="time">{firstEndDuration}</div>
											</>
										)}
									</div>
									<div className="lineDiv">
										<div className="linecontent">
											<div className="iconsSvg"><TripCreated /></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Created</div>
												<div className="lineDetailAmount">{data.firstRangeData?.totalTrips || 0}</div>
											</div>
										</div>
										<div className="linecontent">
										<div className="iconsSvg"><TripCompleted /></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Completed </div>
												<div className="lineDetailAmount">{data.firstRangeData?.totalCompleted || 0}</div>
											</div>
										</div>
									</div>

									<div className="lineDiv">
										<div className="linecontent">
										<div className="iconsSvg"><TripTerminated /></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Terminated</div>
												<div className="lineDetailAmount">{data.firstRangeData?.totalTerminated || 0}</div>
											</div>
										</div>
										<div className="linecontent">
										<div className="iconsSvg"><TripCreated /></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Created/Day</div>
												<div className="lineDetailAmount">{calculateAvg(data?.firstRangeData?.totalTrips, numberOfFirstDays)}</div>
											</div>
										</div>
									</div>
								</div>
								<div className={`percentage ${(data.firstRangeData.totalTrips||0) < (data.secondRangeData?.totalTrips||0) ? "greenn" : "redd"}`}>
									<div style={{display:'flex', gap:'0.4rem'}}>
										{(data.firstRangeData.totalTrips||0) < (data.secondRangeData.totalTrips||0)? (
											<span>
												<TrendingUpIcon />
											</span>
										) : (
											<span>
												<TrendingDownIcon />
											</span>
										)}{" "}
										<div className="percentNumber">{data.firstRangeData?.totalTrips ?calcualtePercentage(data.firstRangeData?.totalTrips, data.secondRangeData?.totalTrips):(data.secondRangeData?.totalTrips||0)*100}%</div>
									</div>
									{(data.firstRangeData.totalTrips||0) <(data.secondRangeData?.totalTrips||0)? (
										<span className="tripIncreases">Trip Increases</span>
									) : (
										<span className="tripIncreases">Trip Decreases</span>
									)}
								</div>
								<div className="rightDetail">
									<div className="duration">
										{/* {secondDuration} <div className="percentage">3% trip increases</div> {startDuration} */}
										{filter.division === "monthly" ? (
											<>
												<div className="time">{secondDuration} </div> To <div className="time">{secondEndDuration}</div>
											</>
										) : (
											<>
												<div className="time">{secondDuration}</div> To <div className="time">{secondEndDuration}</div>
											</>
										)}
									</div>
									<div className="lineDiv">
										<div className="linecontent">
										<div className="iconsSvg"><TripCreated /></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Created</div>
												<div className="lineDetailAmount">{data.secondRangeData?.totalTrips || 0}</div>
											</div>
										</div>
										<div className="linecontent">
										<div className="iconsSvg"><TripCompleted /></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Completed </div>
												<div className="lineDetailAmount">{data.secondRangeData?.totalCompleted || 0}</div>
											</div>
										</div>
									</div>

									<div className="lineDiv">
										<div className="linecontent">
										<div className="iconsSvg"><TripTerminated  className='iconsSvg1'/></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Terminated</div>
												<div className="lineDetailAmount">{data.secondRangeData?.totalTerminated || 0}</div>
											</div>
										</div>
										<div className="linecontent">
										<div className="iconsSvg"><TripCreated /></div>
											<div className="TripStatusAndCount">
												<div className="lineDetailContent">Trip Created/Day</div>
												<div className="lineDetailAmount">{calculateAvg(data?.secondRangeData?.totalTrips, numberOfSecondDays)}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style={{ display: "flex" }}>
								{data.firstRangeData.error === true && data.secondRangeData.error === true ? (
									<h1 style={{ textAlign: "center", width: "100%", marginTop: "2rem" }}> No data found.....</h1>
								) : (
									<>
										<div style={{ flex: "1" }}>
											<NewLineChart
												dataSet={graphData1}
												usersDataSet={graphData1}
												dataSet3={graphData1}
												detail1={series1}
												detail2={series2}
												detail3={series3}
												// division={filter?.division}
												division={"daily"}
												chartId={"chartA"}
											/>
										</div>

										<div style={{ flex: "1" }}>
											<NewLineChart
												dataSet={graphData2}
												usersDataSet={graphData2}
												dataSet3={graphData2}
												detail1={series1}
												detail2={series2}
												detail3={series3}
												// division={filter?.division}
												division={"daily"}
												chartId={"chartB"}
											/>
										</div>
									</>
								)}
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default Analytics;

import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "../utils";
import "./NewDashboard.css";
import { useOrgs } from "../../services/hooks";
import { ConsolidatedStats } from "../utils/Genericstats";
import { useGetDashboardStatsV2Mutation } from "../../api/vmsSlice";
import { useGetUsersDashboardStatsMutation } from "../../api/userSlice";
import dayjs from "dayjs";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { DatePicker, Button } from "../utils";
import { Grid } from "@mui/material";
import theme, { buttonClasses } from "../utils/theme";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import NewLineChart from "./NewLineChart";
import { Loader } from "../utils";

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

am4core.useTheme(am4themes_animated);

const DEFAULT_COMPANY_STATE = {
    id: "",
    name: "",
    status: "Activated",
    activeFrom: "",
};

const DEFAULT_TRIP_FILTER = {
    type: "dateWiseTrips",
    start: dayjs(new Date()).subtract(1, "month"),  
    end: new Date(),
    division: "daily",
};

const DEFAULT_USER_FILTER = {
    type: "users",
    start: dayjs(new Date()).subtract(1, "month"),
    end: new Date(),
    division: "daily",
};

const DEFAULT_DIVISION_OPTIONS = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Yearly", value: "yearly" },
];

export default function CompanyStats({ company = "" }) {
    const [_company, setCompany] = useState(DEFAULT_COMPANY_STATE);
    const { orgOptions, orgs } = useOrgs();
    const [dataSet, setDataSet] = useState([]);
    const [usersDataSet, setUsersDataSet] = useState([]);
    const statsContainer = useRef();
    const filterRef = useRef();
    const [filter, setFilter] = useState({ division: "daily" });
    const [getDashboardStatsV2, { data, isLoading: statsLoading }] = useGetDashboardStatsV2Mutation();
    const [getUsersDashboardStats, { data: usersData, isLoading: userStatsLoading }] = useGetUsersDashboardStatsMutation();

    let deviceOptions = ["devices", "Tag"];
    let usersOptions = ["users", "company", "roles"];
    let vmsOptions = ["product", "productCategories", "supplier", "workflow", "checkpoint", "checkpointType", "trips", "tripsTerminated", "tripsCompleted"];

    const fetchStatus = (filter) => {
        getDashboardStatsV2(filter)
            .unwrap()
            .then((res) => {
                res = transformGraphData(res?.dateWiseData, filter.start, filter.end, ["totalIn", "totalOut"], res?.division);
                setDataSet(res);
            });
    };
    
    const fetchUserStats = (filter) => {
        getUsersDashboardStats(filter)
            .unwrap()
            .then((res) => {
                res = transformGraphData(res?.userStats, filter.start, filter.end, ["total"], res?.division);
                setUsersDataSet(res);
            });
    };

    function onChangeOrganization(value){
        if( !value ){
            setCompany(DEFAULT_COMPANY_STATE);
            fetchStatus(DEFAULT_TRIP_FILTER);
            return;
        }
        const orgInfo = orgs.find((_) => _._id === value);
        setCompany({
            ...DEFAULT_COMPANY_STATE,
            id: orgInfo._id || "",  
            name: orgInfo.name || "",
            status: orgInfo.status || "",
            activeFrom: orgInfo.addedOn || "",
        });
    }

    const handleChange = (value, key) => {
		// console.log("filter on change", { [key]: value });
		setFilter((previous) => {
			return { ...previous, [key]: value === null ? "" : value };
		});
	};

    const handleSubmit = () => {
        if (Object.keys(filter)?.length > 0) {
            if (Object.keys(filter).includes("start") || Object.keys(filter).includes("end")) {
                filterRef.current = {
                    ...filterRef.current,
                    ...{ start: filter?.start || "", end: dayjs(filter?.end).add(1, "day").toDate().toISOString() || "", division: filter?.division || "daily" },
                };
            }

        }
        fetchUserStats({ ...DEFAULT_USER_FILTER, ...filterRef.current, ...(_company?.id ? { orgId: _company?.id } : {}) });
        fetchStatus({ ...DEFAULT_TRIP_FILTER, ...filterRef.current, ...(_company?.id ? { orgId: _company?.id } : {}) });
    };

    const onhandleClear = () => {
        setFilter({ division: "daily" });
        filterRef.current = { start: "", end: "", division: "daily" };
        fetchStatus(DEFAULT_TRIP_FILTER);
        fetchUserStats(DEFAULT_USER_FILTER)
    };
    

    useEffect(() => {
        fetchStatus(DEFAULT_TRIP_FILTER);
        fetchUserStats(DEFAULT_USER_FILTER);

        /**
         * OnWheel is part of React Synthetic Events where preventDefault doesnt work as expected. Using a ref to the actual DOM node to set the event listener as a workaroudn.
         */
        const consolidatedStatsContainer = statsContainer.current;
        if (consolidatedStatsContainer) {
            const handleWheel = (e) => {
                e.preventDefault();
                e.stopPropagation();
                consolidatedStatsContainer.scrollBy({ left: e.deltaY, behavior: "instant" });
            };
            consolidatedStatsContainer.addEventListener("wheel", handleWheel);
            return () => {
                consolidatedStatsContainer.removeEventListener("wheel", handleWheel);
            };
        }
    }, []);

    const series1={
        name:'Trips',
        tooltipText :"Date: {categoryX}\nTrips: {valueY}",
        color: '#228b22', 
        valueY: "totalIn"
    }

    const series2={
        name : "Users",
        tooltipText : "Users Created: {valueY}",
        valueY : "total"
    }
    return (
        <>
            <div className="">
                <div className="contentpanel">
                    <div className="stat-header-wrap">
                        <h1 className="title">LynkID Dashboard</h1>

                        <div className="filter">
                            {_company.id && (
                                <>
                                    <span className="activatedtext">Activated From : {dayjs(_company.activeFrom).format("DD MMMM YYYY")}</span>
                                    <span className="statustext" style={_company.status == "ACTIVE" ? { backgroundColor: "var(--primary-color)" } : { backgroundColor: "var(--red-color)" }}>
                                        Status: {_company.status}
                                    </span>
                                </>
                            )}
                            <div className="company-filter">
                                <Dropdown name="company" label="Company" options={orgOptions} value={orgOptions.find((_) => _.value == _company.id) || null} onChange={(op) => onChangeOrganization(op?.value)} dataTestId="companyFilter" />
                            </div>
                        </div>
                    </div>
                    <hr className="bgg" />
                </div>
                <div
                    id="consolidated-stats-cont"
                    ref={statsContainer}
                    style={{
                        width: "calc(100% - 15px)",
                        marginLeft: "4px",
                        overflowX: "scroll",
                        scrollbarWidth: "none",
                        overflowY: "hidden",
                        WebkitOverflowScrolling: "touch",
                        msOverflowStyle: "none",
                    }}
                >
                    <div className="cust-row flex-algn-cent">
                        <ConsolidatedStats orgId={_company.id} deviceOptions={deviceOptions} usersOptions={usersOptions} vmsOptions={vmsOptions} type={"scroll"} />
                    </div>
                </div>
                <div className="chart-parent-container">
                    <Grid container spacing={"1em"} sx={{ margin: "0.5em 0 0 0", justifyContent: "flex-end", p: "0 55px" }}>
                        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                            <Dropdown name="division" label="Division" options={DEFAULT_DIVISION_OPTIONS} value={DEFAULT_DIVISION_OPTIONS.find((_) => _.value == filter?.division)} onChange={(op) => setFilter((old) => ({ ...old, division: op?.value || "daily" }))} dataTestId="divisionPicker" disableClearable={true} />
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                            <DatePicker name={"start"} minDATE={dayjs("2023-10-01")} maxDATE={dayjs()} value={filter?.start || null} required onChange={(val) => handleChange(val.toISOString(), "start")} label={"Start Date"} />
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                            <DatePicker name={"start"} minDATE={filter?.start ? dayjs(filter?.start) : null} maxDATE={dayjs()} value={filter?.end || null} required onChange={(val) => handleChange(val.toISOString(), "end")} label="End Date" />
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={3} xs={12}>
                            <Button
                                testId={"Clear"}
                                text={"Clear"}
                                style={buttonClasses.outlined}
                                // disabled={false}
                                onClick={() => onhandleClear()}
                            />
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={3} xs={12}>
                            <Button testId={"Submit"} text={"Submit"} style={buttonClasses.outlined} disabled={!filter?.start || !filter?.end} onClick={() => handleSubmit()} />
                        </Grid>
                    </Grid>
                    <div className="table-chart-container" style={{ minHeight: "500px", alignItems: "center" }}>
                        {statsLoading || userStatsLoading ? (
                            <Loader size="2rem" />
                        ) : (
                            <div className="chart-container">
                                <NewLineChart dataSet={dataSet} usersDataSet={usersDataSet} detail1={series1} detail2={series2} division={filter?.division} />
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ minHeight: "5vh" }}></div>
            </div>
        </>
    );
}

function transformGraphData(data, start, end, keyArr, division) {
    switch (division) {
        case "daily":
            return validateHasAllDay(data, start, end, keyArr);
        case "weekly":
            return validateHasAllWeeks(data, start, end, keyArr);
        case "monthly": {
            return validateHasAllMonths(data, start, end, keyArr);
        }
        case "yearly": {
            return validateHasAllYears(data, start, end, keyArr);
        }
        default: {
            return data;
        }
    }
}

const validateHasAllDay = (data, start, end, keyArr) => {
    const generateDateRange = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dates = [];
        while (start <= end) {
            dates.push(start.toISOString().split("T")[0]); // Format as YYYY-MM-DD
            start.setDate(start.getDate() + 1); // Increment by 1 day
        }
        return dates;
    };
    const allDates = generateDateRange(start, end);
    const dataMap = data.reduce((map, item) => {
        map[item._id] = { ...item };
        return map;
    }, {});

    const result = allDates.map((date) => {
        let res = {
            _id: date,
        };
        for( let key of keyArr ){
            res[key] = dataMap[date]?.[key] || 0;
        }
        return res;
    });
    return result;
};

function validateHasAllWeeks(data, start, end, keyArr) {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    let current = startDate;
    let last = endDate.endOf("isoWeek");
    const allWeeksSet = [];
    while (current.isBefore(last)) {
        allWeeksSet.push(`${current.isoWeekYear()}-${current.week() < 9 ? "0" + current.week() : current.week()}`);
        current = current.add(1, "week");
    }
    allWeeksSet.pop(); // remove extra week
    const dataMap = new Map();
    data.forEach((item) => {
        dataMap.set(item._id, item);
    });

    const result = [];
    allWeeksSet.forEach((week) => {
        if (dataMap.has(week)) {
            result.push(dataMap.get(week));
        } else {
            const missingWeek = { _id: week };
            keyArr.forEach((key) => {
                missingWeek[key] = 0;
            });
            result.push(missingWeek);
        }
    });
    return result;
}

const validateHasAllMonths = (data, start, end, keyArr) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    let current = startDate.startOf("month");
    let last = endDate.endOf("month");
    const allMonthsSet = [];
    while (current.isBefore(last)) {
        allMonthsSet.push(`${current.year()}-${current.month() < 9 ? "0" + (current.month() + 1) : current.month() + 1}`);
        current = current.add(1, "month");
    }
    const dataMap = new Map();
    data.forEach((item) => {
        dataMap.set(item._id, item);
    });

    const result = [];
    allMonthsSet.forEach((month) => {
        if (dataMap.has(month)) {
            result.push(dataMap.get(month));
        } else {
            const missingMonth = { _id: month };
            keyArr.forEach((key) => {
                missingMonth[key] = 0;
            });
            result.push(missingMonth);
        }
    });
    return result;
};

const validateHasAllYears = (data, start, end, keyArr) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    let current = startDate.startOf("year");
    let last = endDate.endOf("year");
    const allYearsSet = [];
    while (current.isBefore(last)) {
        allYearsSet.push(`${current.year()}`);
        current = current.add(1, "year");
    }
    const dataMap = new Map();
    data.forEach((item) => {
        dataMap.set(item._id, item);
    });
    const result = [];
    allYearsSet.forEach((year) => {
        if (dataMap.has(year)) {
            result.push(dataMap.get(year));
        } else {
            const missingYear = { _id: year };
            keyArr.forEach((key) => {
                missingYear[key] = 0;
            });
            result.push(missingYear);
        }
    });
    return result;
}

import React, { useEffect, useRef, useState } from "react";
import { Dialog, Input, Loader } from "../utils";
import { SearchDropdown, Dropdown } from "../utils";
import { Button } from "../utils";
import { buttonClasses } from "../utils/theme";
import { useCheckpointData } from "../../services/hooks";
import { useUpdateManageTripMutation, useModifyTripMutation } from "../../api/api";
import { useGetApprovalConfigMutation, useGetApprovalDetailMutation, useVehicleDetailsMutation } from "../../api/vmsSlice";
import { capitalizeCamelCase, capitalizeSentence, findDeepValue, formatDate, parseUnderscore, removeSpecialChars } from "../../services/functions";
import { toastMessage } from "../utils";
import "./Trip.css";
import { useLocation } from "react-router";
import { CloseIcon, SearchIcon } from "../../services/icons";
import DynamicFormRender from "../Dynamic/DynamicForm";
import { useUserDetail } from "../../services/hooks";
import { useUserPermissions } from "../../services/hooks";
import { useGetTripcheckpointMutation } from "../../api/vmsSlice";
import { CheckIcon } from "../../services/icons";
import AllCheckpointData from "../Dashboard/AllCheckpointData";

const defaultFilter = {
	checkpointId: "all",
};

export default function TripIndex() {
	const filterRef = useRef(defaultFilter);
	const { userPermissions } = useUserPermissions();
	const [isModifying, setIsModifying] = useState(false);
	const [searchVehicle, setSearchVehicle] = useState("");
	const [searchTrigger, setSearchTrigger] = useState(false);
	const [selectedVehicle, setSelectedVehicle] = useState({});
	const [vehicledata, setVehicleData] = useState({});
	const [vehicleTableHeader, setVehicleTableHeader] = useState([]);
	const [allCheckpointList, setAllCheckpointList] = useState([]);
	const [forcedEndTrip, setForcedEndTrip] = useState(false);
	const [cancelTrip, setCancelTrip] = useState(false);
	const [remarks, setRemarks] = useState("");
	const [error, setError] = useState({
		searchBy: "",
		checkpointId: "",
		remarks: "",
	});
	const [logStatus, setLogStatus] = useState({});
	const { userDetail } = useUserDetail();
	// console.log('userDetail', userDetail)
	const { trigger, checkpointData, isLoading, isFetching, count, message, error: checkpointError } = useCheckpointData(filterRef.current);
	const [updateManageTrip] = useUpdateManageTripMutation();
	const [modifyTripTrigger] = useModifyTripMutation();
	const [vehicleDetailTrigger] = useVehicleDetailsMutation();
	const [getTripcheckpointTrigger] = useGetTripcheckpointMutation();
	const location = useLocation();

	const handleChange = (value, key) => {
		// console.log('value', value, 'key', key);
		setSearchVehicle((previous) => {
			return { [key]: value === null ? "" : value };
		});
	};

	const onhandleClear = () => {
		setSearchTrigger(true);
		setSearchVehicle("");
	};

	const handleOpenNewTab = (data) => {
		// Construct the new URL based on the current location
		const newRoute = "/map-view"; // Replace with your desired route
		const newUrl = `${window.location.origin}${newRoute}?data=${encodeURIComponent(data)}`;

		// Open the new route in a new tab
		window.open(newUrl, "_blank");
	};

	const onHandleSearch = () => {
		// console.log('search', searchVehicle);
		setIsModifying(false);
		// convertTextToSpeech(JSON.stringify(searchVehicle));
		if (!searchVehicle) {
			setError({ ...error, searchBy: "Please entered search value" });
		} else {
			setError({ ...error, searchBy: "" });
		}
		filterRef.current = { ...filterRef.current, ...{ searchBy: Object.keys(searchVehicle), search: Object.values(searchVehicle) } };
		if (Object.keys(searchVehicle).length > 0) {
			setSearchTrigger(false);
			trigger(filterRef.current);
		}
	};

	// console.log('checkpointData', checkpointData);

	useEffect(() => {
		if (location?.state?.data?._id) {
			handleVehicleDetail(location.state.data._id);
		}
	}, [location]);

	const handleVehicleDetail = (id) => {
		getTripcheckpointTrigger({ type: "all", tripId: id })
			.unwrap()
			.then((res) => {
				// console.log('detail-chk', res.data);
				if (res?.error) {
					toastMessage(false, res.message);
				} else {
					setAllCheckpointList(res?.data || []);
				}
			})
			.catch((err) => {
				// console.log("err", err);
			});
		vehicleDetailTrigger({ vehicleEntryId: id })
			.unwrap()
			.then((res) => {
				// console.log('detail-status-data', res.data.data);
				// console.log('detail-status', res.data.statusHistory);
				setVehicleData(res?.data || {});
				setSelectedVehicle(res?.data || {});
				setVehicleTableHeader(res?.tableHeader || []);
				// generateDetail(res.data, res.tableHeader)
			})
			.catch((err) => {
				// console.log("err", err);
			});
	};

	const onHandleTrip = () => {
		if (!selectedVehicle.vehicleId) {
			return toastMessage(false, "Vehicle not selected");
		}
		if (remarks.length <= 0) {
			return setError({ ...error, remarks: "Remarks should be required" });
			// return toastMessage(false,'Remarks should be required')
		}

		const payload = {
			vehicleEntryId: selectedVehicle._id,
			remarks: remarks,
			forceEndTrip: forcedEndTrip,
			cancelTrip: cancelTrip,
		};

		// console.log(payload);

		updateManageTrip(payload)
			.unwrap()
			.then((res) => {
				if (res?.error) {
					return toastMessage(false, res.message);
				}
				handleVehicleDetail(selectedVehicle._id);
				// toastMessage(true, 'Trip Updated Successfully')
				setIsModifying(false);
				toastMessage(true, res.message);
				setForcedEndTrip(false);
				setCancelTrip(false);
				setRemarks("");
				setSelectedVehicle({});
				setSearchVehicle("");
				setSearchTrigger(false);
			})
			.catch((err) => {
				toastMessage(false, err.message);
			});
	};

	const handleLogStatus = (status) => {
		let TempStatus = JSON.parse(JSON.stringify({ ...logStatus }));
		if (TempStatus.hasOwnProperty(status) && TempStatus[status] === true) {
			TempStatus[status] = false;
		} else {
			TempStatus[status] = true;
		}
		setLogStatus(TempStatus);
	};

	function flattenObj(res, obj = {}) {
		for (let key in obj) {
			if (Array.isArray(obj[key])) {
				if (obj[key].every((i) => typeof i == "string")) {
					res[key] = obj[key].join(",");
				} else if (obj[key].every((i) => typeof i === "object")) {
					if (obj[key].every((subObj) => typeof subObj[key + "Name"] === "string")) {
						// static hack for camelcase [key]Name array of objects
						res[key] = obj[key].reduce((p, c) => p + c[key + "Name"] + ", ", "").replace(/,\s*$/, "");
					}
				}
			} else if (typeof obj[key] == "object") {
				flattenObj(res, obj[key]);
			} else {
				res[key] = obj[key];
			}
		}
	}

	const generateDetail = (data, checkpointList) => {
		if (!data) return [];
		const newStaticDetail =
			checkpointList?.map((ele, index) => {
				const { order, checkpoints = [], optional, visited } = ele;
				let currChkpt = checkpoints[0];
				let checkPointStatus = checkpoints.map((ele, index) => (
					<div style={visited ? { backgroundColor: "var(--primary-color)" } : { backgroundColor: "var(--dark-gray-color)" }} className="status-clip" key={`checkpoint-key-${index}`}>
						{ele.displayName}
					</div>
				));
				const status = visited ? data.statusHistory.find((ele, index) => ele.checkpointId === checkpoints[0]._id) : null;
				const allStatus = visited ? data.statusHistory.filter((ele, index) => ele.checkpointId === checkpoints[0]._id) : null;
				let type;
				let res = data?.data?.map((_, i) => {
					let result = {};
					if (_.checkpointId === status?.checkpointId) {
						//previous==>(_.checkpointId === status?.checkpointId)
						flattenObj(result, _.formData);
						type = _.type;
						let dateStatus = allStatus.find((el) => el._id === _._id);
						const findInTime = status?.checkpointType == "access" ? "Approval Request Time" : status?.checkpointType == "bay" ? "Dock Operation Start" : "In Time";
						const findOutTime = status?.checkpointType == "access" ? "Approval Accepted Time" : status?.checkpointType == "bay" ? "Dock Operation End" : "Out Time";
						result[findInTime] = formatDate(dateStatus?.inTime || status?.inTime);
						result[findOutTime] = formatDate(dateStatus?.outTime || status?.outTime);
						delete result._id;
					}
					return result;
				});
				// console.log('res', res)
				let rows = [];
				let uniqueId = 0; // Initialize a unique identifier
				for (let obj of res) {
					let temp = [];
					for (let key in obj) {
						if (obj[key]) {
							temp.push(
								<div className="detail_row" key={`${key}-${uniqueId}`}>
									<div className="detail_row_title">{capitalizeSentence(parseUnderscore(capitalizeCamelCase(key)))}</div>
									<div className="detail_row_value">{obj[key]}</div>
								</div>
							);
							uniqueId++; // Increment the unique identifier
						}
					}
					if (Object.keys(obj).length > 0) {
						rows.push(temp);
					}
				}

				// console.log('rows', rows)
				// rows.push(
				// 	<div className="detail_row" key={`${order}-${status?.checkpointId}-inTime`}>
				// 		<div className="detail_row_title">{status?.checkpointType == "access" ? "Approval Request Time" : status?.checkpointType == "bay" ? "Dock Operation Start" : "In Time"}</div>
				// 		<div className="detail_row_value">{formatDate(status?.inTime) || "-"}</div>
				// 	</div>
				// );

				// rows.push(
				// 	<div className="detail_row" key={`${order}-${status?.checkpointId}-outTime`}>
				// 		<div className="detail_row_title">{status?.checkpointType == "access" ? "Approval Accepted Time" : status?.checkpointType == "bay" ? "Dock Operation End" : "Out Time"}</div>
				// 		<div className="detail_row_value">{formatDate(status?.outTime) || "-"}</div>
				// 	</div>
				// );
				const view = (
					<div key={index} className="statusHistory-container">
						<div className="statuslayout-container">
							<div className="status-connector-container">
								<div
									style={index === 0 ? { backgroundColor: "var(--background-color)" } : visited ? { backgroundColor: "green" } : { backgroundColor: "var(--dark-gray-color)" }}
									className="status-connector-line"
								></div>
								<div style={visited ? { backgroundColor: "green" } : { backgroundColor: "var(--dark-gray-color)" }} className="status-connector-circle">
									{visited && <CheckIcon fontSize={"small"} htmlColor={"#fff"} />}
								</div>
								<div
									style={
										index === checkpointList.length - 1
											? { backgroundColor: "var(--background-color)" }
											: visited
											? { backgroundColor: "green" }
											: { backgroundColor: "var(--dark-gray-color)" }
									}
									className="status-connector-line"
								></div>
							</div>
							<div className="status-checkpoint-container">{checkPointStatus}</div>
						</div>
						<div className="statusHistory-detail-content">
							<div style={{ fontWeight: "bold", textAlign: "center", color: "var(--text-color)" }}>
								{status?.displayName || ""}
								{type && `(${capitalizeCamelCase(type)})`}
							</div>
							{rows.length > 1 && (
								<div className="logbutton-div">
									<button onClick={() => handleLogStatus(status?.displayName)} className="logbutton">
										{logStatus[status?.displayName] ? "- " : "+ "}Show Full Status Log
									</button>
								</div>
							)}
							<div style={{ padding: "0.5rem", borderRadius: "12px", color: "var(--text-color)" }}>
								{logStatus[status?.displayName]
									? rows.map((row) =>
											row?.length ? <div style={{ border: "1px dotted var(--gray-color)", marginBottom: "10px", padding: "5px", borderRadius: "10px" }}>{row}</div> : null
									  )
									: [rows[rows?.length - 1]].map((row) =>
											row?.length ? (
												<div>{row}</div>
											) : (
												<div className="detail_row">
													<div className="detail_row_title">In Time</div>
													<div className="detail_row_value">-</div>
												</div>
											)
									  )}
								{currChkpt?.sendApproval ? <ApprovalStatus vehicleEntryDetail={data} checkpointDetail={currChkpt} /> : null}
							</div>
						</div>
					</div>
				);
				return view;
			}) || [];
		// const newStaticDetail = data.statusHistory?.map(({ displayName, inTime, outTime, checkpointId, checkpointType }, i) => {
		//     // { type, formData, checkpointId }
		//     let type;
		//     let res = data?.data?.map((_, i) => {
		//         let result = {}
		//         if (_.checkpointId === checkpointId) {
		//             flattenObj(result, _.formData);
		//             type = _.type;
		//             delete result._id;
		//         }
		//         return result;
		//     })
		//     let rows = [];
		//     for (let obj of res) {
		//         for (let key in obj) {
		//             if (obj[key]) {
		//                 rows.push(
		//                     <div className="detail_row" key={key}>
		//                         <div className="detail_row_title">{capitalizeSentence(parseUnderscore(capitalizeCamelCase(key)))}</div>
		//                         <div className="detail_row_value">{obj[key]}</div>
		//                     </div>
		//                 );
		//             }
		//         }
		//     }
		//     rows.push(
		//         <div className="detail_row" key={inTime + displayName}>
		//             <div className="detail_row_title">{checkpointType == 'access' ? 'Approval Request Time' : checkpointType == 'bay' ? 'Dock Operation Start' : 'In Time'}</div>
		//             <div className="detail_row_value">{formatDate(inTime)}</div>
		//         </div>
		//     );
		//     rows.push(
		//         <div className="detail_row" key={outTime + i + displayName}>
		//             <div className="detail_row_title">{checkpointType == 'access' ? 'Approval Accepted Time' : checkpointType == 'bay' ? 'Dock Operation End' : 'Out Time'}</div>
		//             <div className="detail_row_value">{formatDate(outTime)}</div>
		//         </div>
		//     );
		//     const view = (
		//         <div key={i} className='statusHistory-detail-content'>
		//             <div style={{ fontWeight: "bold", textAlign: "center", color: "var(--text-color)" }}>
		//                 {displayName} {type && `(${capitalizeCamelCase(type)})`}
		//             </div>
		//             <div style={{ padding: "0.5rem", borderRadius: "12px", color: "var(--text-color)" }}>{rows}</div>
		//         </div>
		//     );
		//     return view;

		// })
		return <>{newStaticDetail}</>;
	};

	useEffect(() => {
		if (checkpointError && !isLoading) {
			toastMessage(false, message);
		}
	}, [isLoading]);
	//dynamic form
	const handleClose = () => {
		// console.log('handleClose called');
		setIsModifying(!isModifying);
	};
	const handleFormSubmit = (data) => {
		const payload = {
			vehicleEntryId: selectedVehicle._id,
			...data?.data,
		};
		// console.log('Form submitted', payload);
		modifyTripTrigger(payload)
			.unwrap()
			.then((res) => {
				if (res?.error) {
					return toastMessage(false, res.message);
				}
				// toastMessage(true, 'Trip Updated Successfully');
				handleVehicleDetail(selectedVehicle._id);
				setIsModifying(false);
				toastMessage(true, res.message);
				setForcedEndTrip(false);
				setCancelTrip(false);
				setRemarks("");
				setSelectedVehicle({});
				setSearchVehicle("");
				setSearchTrigger(false);
			})
			.catch((err) => {
				toastMessage(false, err.message);
			});
	};
	return (
		<>
			<div className="contentpanel" onClick={() => (!searchTrigger ? setSearchTrigger(true) : null)}>
				<div className="cust-row flex-algn-cent">
					<div className="cust-col-5">
						<h1 className="title">Manage Trip</h1>
					</div>
					<div className="cust-col-5 flex-jc-end gap-3"></div>
				</div>
				<hr className="bgg" />
				<div className="trip-search-section">
					<div className="trip-search-section-left">
						<SearchDropdown
							id={"vehicleNumber"}
							name={"vehicleNumber"}
							options={[
								{ label: "Vehicle Number", value: "vehicleNumber" },
								{ label: "Trip ID", value: "trip_counter" },
							]}
							preSelectedOption={{ label: "Vehicle Number", value: "vehicleNumber" }}
							label={"Search by"}
							placeholder={"Enter value"}
							value={searchVehicle}
							Debounce={true}
							textBorderRadius="1px 0px 0px 1px"
							selectBorderRadius="20px 1px 1px 20px"
							adornment={{
								is: true,
								onClick: () => {
									setSearchTrigger(true);
									setSearchVehicle("");
								},
								type: isLoading ? "loader" : "CloseIcon",
							}}
							onDropdownChange={onhandleClear}
							onChange={(value, key) => handleChange(value, key)}
							onhandleClear={onhandleClear}
						/>
						<div style={{ textAlign: "start" }}>{error?.searchBy ? <span style={{ color: "var(--red-color)", fontSize: "12px" }}>{error.searchBy}</span> : null}</div>
						<div>
							{!searchTrigger && checkpointData?.length > 0 ? (
								<div className={"trip-search-section-left-list"}>
									<ul>
										{checkpointData.map((ele, index) => (
											<li
												className={ele._id === selectedVehicle._id ? "active" : null}
												key={ele._id}
												onClick={() => {
													// console.log('selected vehicle', ele)
													handleVehicleDetail(ele._id);
												}}
											>
												<span style={{ fontSize: "14px", fontWeight: "bold" }}>Vehicle No. : </span>
												<span style={{ fontSize: "14px" }}>{`${ele.vehicleNumber}`}</span>
												<span style={{ fontSize: "14px", fontWeight: "bold" }}>, Trip ID :</span>
												<span style={{ fontSize: "14px" }}>{`${ele.trip_counter}`}</span>
											</li>
										))}
									</ul>
								</div>
							) : null}
						</div>
					</div>
					<div className="trip-search-section-right">
						<Button
							text={<SearchIcon />}
							style={{ ...buttonClasses.lynkitOrangeFill, height: "45px", minWidth: "45px", borderRadius: "50%", transform: "Translate(-12px, -3px)" }}
							onClick={() => {
								onHandleSearch();
							}}
						/>
					</div>
				</div>
				{Object.keys(vehicledata).length > 0 ? (
					<div className="trip-detail-section">
						<div className="trip-detail-container">
							<div className="trip-detail-header">
								<h4>{!isModifying ? "Vehicle Detail" : "Modify Trip"}</h4>
								<div>
									<div>
										{userPermissions?.modifyTrip?.value && !isModifying && !selectedVehicle?.trip_over && (
											<span className="modify-btn" onClick={() => setIsModifying(!isModifying)}>
												Modify Trip
											</span>
										)}
										<button className="modify-btn" onClick={() => handleOpenNewTab(selectedVehicle._id)}>
											View Map
										</button>
									</div>
								</div>
							</div>
							{isModifying ? (
								<>
									<div style={{ padding: "0px 20px" }}>
										<div>
											<p style={{ color: "var(--red-color)", fontSize: "14px" }}>
												{`**Note: Once you click Submit the trip the current trip with ID ${selectedVehicle?.trip_counter} will be concluded, and the timestamp for this action will be recorded as the current time for upcoming checkpoints of current trip.`}
											</p>
										</div>
										<DynamicFormRender
											templatePayload={{
												organization_id: userDetail.orgId,
												template_name: "modifyTrip",
												type: "modifyTrip",
											}}
											checkoutData={{ trip_counter: vehicledata.trip_counter }}
											onClose={handleClose}
											onSubmit={handleFormSubmit}
											// isSubmitDisabled={isSubmitting}
										/>
									</div>
								</>
							) : (
								<>
									<div className="trip-detail-content">
										{vehicleTableHeader.length > 0 &&
											vehicleTableHeader.map((ele, index) => (
												<div key={index} className="trip-detail-content-field">
													<div
														style={isModifying && (ele.label == "Product" || ele.label == "Product Category") ? { paddingTop: "5px" } : {}}
														className="trip-detail-content-field-label"
													>
														<span>{ele.label} :</span>
													</div>
													<div className="trip-detail-content-field-value">
														{isModifying && (ele.label == "Product" || ele.label == "Product Category") ? (
															<span>
																<Dropdown
																	id={ele.label}
																	name={ele.label == "Product" ? "product" : ele.label == "Product Category" ? "productCategory" : null}
																	value={null}
																	options={[]}
																	label={ele.label}
																	// onChange={(option, key) => onHandleCheckpointDetailChange(option, key)}
																	// error={error?.checkPoint?.orgId}
																/>
															</span>
														) : (
															<span>
																{ele.type == "date" ? formatDate(findDeepValue(vehicledata, ele.name.split("."))) : findDeepValue(vehicledata, ele.name.split("."))}
															</span>
														)}
													</div>
												</div>
											))}
									</div>
									<div>{allCheckpointList.length > 0 && vehicleTableHeader.length > 0 && generateDetail(vehicledata, allCheckpointList)}</div>
								</>
							)}
						</div>
					</div>
				) : (
					<>
						<AllCheckpointData listOnly />
					</>
				)}
				{Object.keys(selectedVehicle).length > 0 && !selectedVehicle?.trip_over && !isModifying ? (
					<div className="trip-button-section">
						{userPermissions?.cancelTrip?.value && (
							<div>
								{!cancelTrip ? (
									<Button
										text="Cancel Trip"
										style={{ ...buttonClasses.lynkitOrangeFill, height: "40px", marginRight: "10px" }}
										onClick={() => {
											setCancelTrip(true);
											setForcedEndTrip(false);
											setRemarks("");
											setError({ ...error, remarks: "" });
										}}
									/>
								) : (
									<div className="trip-cancel-section">
										<div>
											<Input
												id={"remark"}
												name={"remark"}
												value={remarks}
												onChange={(e) => {
													setRemarks(e.target.value);
												}}
												label={"Remarks"}
												placeholder={"Enter Remarks"}
												required={true}
												error={error?.remarks}
											/>
										</div>
										<div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
											<Button
												text="Submit"
												style={{ ...buttonClasses.lynkitOrangeFill, height: "40px", marginRight: "10px" }}
												onClick={() => {
													onHandleTrip();
												}}
											/>
										</div>
									</div>
								)}
							</div>
						)}
						{userPermissions?.forceEndTrip?.value && (
							<div>
								{!forcedEndTrip ? (
									<Button
										text="Forced End Trip"
										style={{ ...buttonClasses.lynkitOrangeFill, height: "40px" }}
										onClick={() => {
											setForcedEndTrip(true);
											setCancelTrip(false);
											setRemarks("");
											setError({ ...error, remarks: "" });
										}}
									/>
								) : (
									<div className="trip-cancel-section">
										<div>
											<Input
												id={"remark"}
												name={"remark"}
												value={remarks}
												onChange={(e) => {
													setRemarks(e.target.value);
												}}
												label={"Remarks"}
												placeholder={"Enter Remarks"}
												required={true}
												error={error?.remarks}
											/>
										</div>
										<div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
											<Button
												text="Submit"
												style={{ ...buttonClasses.lynkitOrangeFill, height: "40px", marginRight: "10px" }}
												onClick={() => {
													onHandleTrip();
												}}
											/>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				) : null}
			</div>
		</>
	);
}

function ApprovalStatus({ vehicleEntryDetail, checkpointDetail }) {
	const { userDetail } = useUserDetail();

	const [getApprovalDetail, { data: approvalDetailData, isLoading: isApprovalDetailLoading }] = useGetApprovalDetailMutation();
	const { data: approvalDetails = [] } = approvalDetailData || { data: [] };

	const [getApprovalConfig, { data: approvalConfigData, isLoading: isApprovalConfigLoading }] = useGetApprovalConfigMutation();
	const { data: approvalConfig } = approvalConfigData || { data: [] };

	useEffect(() => {
		if (checkpointDetail) {
			getApprovalDetail({ reference_id: vehicleEntryDetail._id, module: checkpointDetail.checkpointStatus });

			const currentCheckpointEntry = vehicleEntryDetail.statusHistory.find((_) => _.checkpointId == checkpointDetail._id);
			// console.log("currentCheckpointEntry", currentCheckpointEntry)
			if (currentCheckpointEntry?.currentApprovalConfig) {
				getApprovalConfig({ id: currentCheckpointEntry.currentApprovalConfig });
			}
			// let { sendApproval, dependentApproval, approvaldependancy = [], approvalId } = checkpointDetail;
			// if (sendApproval && !dependentApproval) {
			// 	// the approval config selected will always be the approvalId outside
			// 	if (approvalId) {
			// 		// get approval config of this id
			// 		getApprovalConfig({ id: approvalId });
			// 	} else {
			// 		// toastMessage(false, "There is no Approval Config defined for this checkpoint");
			// 	}
			// } else if (sendApproval && dependentApproval) {
			// 	// now we will check the approvalDependancy
			// 	// for each approvalInfo in the approvalDependancy, we will check the itemType and id array and compare it with the data that matches the itemType in the vehicle entry details
			// 	// if the particular id exists in the data, then we will take the corresponding approvalId
			// 	approvaldependancy.forEach((item) => {
			// 		let dataItem = vehicleEntryDetail[item.itemType];
			// 		if (dataItem) {
			// 			// console.log(dataItem, checkpointDetail)
			// 			if (Array.isArray(dataItem)) {
			// 				if (item.itemType == "product" && !item.approvalId) {
			// 					// special case here is the product
			// 					// for Haier, we have product priority usecase
			// 					// so simply, there doesn't exist any approvalConfig, so there won't be any approvalId. In that case, we will simply show whatver approval details exists
			// 				} else if (dataItem.find((_) => item.id.includes(_._id))) {
			// 					// item.approvalId
			// 					if (item.approvalId) {
			// 						// console.log("data item array")
			// 						getApprovalConfig({ id: item.approvalId });
			// 					} else {
			// 						// toastMessage(false, "There is no Approval Config defined for this checkpoint");
			// 					}
			// 				}
			// 			} else {
			// 				if (item.id.includes(dataItem._id)) {
			// 					// item.approvalId
			// 					if (item.approvalId) {
			// 						// console.log("data item", item);
			// 						getApprovalConfig({ id: item.approvalId });
			// 					} else {
			// 						// toastMessage(false, "There is no Approval Config defined for this checkpoint");
			// 					}
			// 				}
			// 			}
			// 		}
			// 	});
			// }
		}
	}, [vehicleEntryDetail]);

	// useEffect(()=>{
	// 	console.log(vehicleEntryDetail)
	// }, [vehicleEntryDetail])
	let approvalsUi = [];

	let approvalLayers = [];

	if (approvalConfig?.length) {
		let approval_details = JSON.parse(JSON.stringify(approvalDetails || []));

		approvalConfig[0]?.approval_layer?.forEach((approval_layer, i) => {
			let approvalDetail;
			let approvalIndex;
			for (let i = 0; i < approval_details.length; i++) {
				let item = approval_details[i];

				if (item.approval_detail) {
					if (item.approval_detail?.email == approval_layer.user_details?.email || item.approval_detail.userRole == approval_layer.role) {
						approvalIndex = i;
						break;
					}
				} else {
					if (item.nextApprovalDetails?.find((_) => _.email == approval_layer.user_details?.email || _.userRole == approval_layer.role)) {
						approvalIndex = i;
						break;
					}
				}
			}

			approvalDetail = approval_details[approvalIndex];
			approval_details.splice(approvalIndex, 1);

			// approvalDetail = approvalDetails?.find((item) => {
			// 	if (item.approval_detail) {
			// 		if (approval_layer.require_user_approval) {
			// 			return item.approval_detail.email == approval_layer.user_details?.email;
			// 		} else {
			// 			return item.approval_detail.userRole == approval_layer.role;
			// 		}
			// 	} else {
			// 		return !!item.nextApprovalDetails?.find((_) => _.email == approval_layer.user_details?.email);
			// 	}
			// });
			let approver_detail =
				approvalDetail?.approval_detail || approvalDetail?.nextApprovalDetails?.find((_) => _.email == approval_layer.user_details?.email || _.userRole == approval_layer.role);

			let canApprove = false;
			if (!approvalDetail || approvalDetail.isRejected) {
				if (approver_detail?.id == userDetail?.userId || (!approval_layer.require_user_approval && approval_layer.role_id == userDetail?.userRoleId)) {
					canApprove = true;
				}
				if (canApprove) {
					canApprove = !approvalConfig[0].approval_layer?.some((el, j) => j < i && el.approval_require && (!approvalDetail || approvalDetail.isRejected));
				}
			}

			approvalLayers.push({
				user: approval_layer.require_user_approval ? approval_layer.user_details?.name : approval_layer.role,
				status: getApprovalStatus(approvalDetail),
				canApprove,
			});
		});
	} else if (approvalDetails?.length) {
		approvalDetails?.forEach((approvalDetail, i) => {
			let canApprove = false;
			// if (!approvalDetail || approvalDetail.isRejected) {
			// 	if (approvalDetail?.approval_detail.id == userDetail?.userId || (!_.require_user_approval && _.role_id == userDetail?.userRoleId)) {
			// 		canApprove = true;
			// 	}
			// 	if (canApprove) {
			// 		canApprove = !approvalConfig[0].approval_layer?.some((el, j) => j < i && el.approval_require && (!approvalDetail || approvalDetail.isRejected));
			// 	}
			// }

			approvalLayers.push({
				user: approvalDetail.approval_detail?.name || approvalDetail.approval_detail?.email || approvalDetail.approval_detail?.userRole,
				status: getApprovalStatus(approvalDetail),
				canApprove: false,
			});
		});
	}
	approvalsUi = approvalLayers.map((_, i) => {
		return (
			<div key={i} className={`approval_status_item ${_.status == "Approved" ? "approved" : _.status == "Rejected" ? "rejected" : "pending"}`}>
				<div className="status-connector-circle">
					{_.status == "Approved" ? <CheckIcon fontSize={"small"} htmlColor={"#fff"} /> : _.status == "Rejected" ? <CloseIcon fontSize="small" htmlColor="#fff" /> : null}
				</div>
				<div style={{ textAlign: "center" }}>
					<div>Status : {_.status}</div>
					<div>{_.user}</div>
				</div>
				{/* <Button text="Approve" disabled={!_.canApprove} /> */}
			</div>
		);
	});

	let statusUi = <div className="approval_status_wrap">{isApprovalConfigLoading || isApprovalDetailLoading ? <Loader size="1rem" /> : approvalsUi}</div>;

	return statusUi;
}
function getApprovalStatus(approvalDetail) {
	return approvalDetail
		? approvalDetail.approvalStatus?.toLowerCase() == "hold"
			? "Hold"
			: approvalDetail.approvalStatus.includes("moveTo")
			? "Move To"
			: approvalDetail.approvalStatus?.toLowerCase() == "pending"
			? "Pending"
			: approvalDetail.isRejected
			? "Rejected"
			: "Approved"
		: "Pending";
}

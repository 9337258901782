import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomInput } from "./Utils";
import { AddRemove, Button, DataNotFound, Loader } from "../utils";
import { useTemplateDetail } from "../../services/hooks";
import { customAPI, debounce, base64Convert, toastMessage, parseUnderscore, capitalizeCamelCase, removeSpecialChars, compareObj, removeEmptyKeysFromObj } from "../../services/functions";
import { buttonClasses, textClasses } from "../utils/theme";
import { InputLabel, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getPathValue, getSelf, isVisible, validateFields } from "./helper";

import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export default function DynamicFormRender({
	templateId,
	templatePayload,
	checkoutData = null,
	checkpointId,
	onClose,
	isSaveDraft = false,
	onSubmit,
	extraClass = "",
	btnText = "Back",
	btn2Text = "Submit",
	isSubmitDisabled = false,
	updateData = null,
	previewAttributes = null,

	filterOnly = false,
	onFilterChange = () => {},
	noBtn = false,
	renderAction = () => {},
	renderHeader = () => {},
}) {
	const [attributes, setAttributes] = useState([]);
	const { isLoading, error: templateDetailError, getTemplateDetail } = useTemplateDetail();
	useEffect(() => {
		if (templateId) {
			getTemplateDetail({ id: templateId })
				.unwrap()
				.then((res) => {
					if (res.data?.attributes) {
						if (!!updateData) {
							let updatedAttrs = res.data.attributes.map((attr) => {
								return { ...attr, value: updateData[attr.name || attr._id] };
							});
							setAttributes(updatedAttrs);
						} else {
							setAttributes(res.data.attributes);
						}
					}
				});
		}
	}, [templateId]);
	useEffect(() => {
		if (templatePayload) {
			getTemplateDetail(templatePayload);
		}
	}, [templatePayload]);
	const autoFillOnFocus = "";
	// const fieldValueMapRef = useRef({});
	// function getFieldValuesFrom(attribute, prefix = "") {
	// 	if (Array.isArray(attribute)) {
	// 		attribute.forEach((attr) => getFieldValuesFrom(attr));
	// 	} else {
	// 		fieldValueMapRef.current[`${prefix}${attribute._id}`] = attribute;

	// 		if (attribute.childrens?.length) {
	// 			attribute.childrens.forEach((attr) => getFieldValuesFrom(attr, `${prefix}${attribute._id}-`));
	// 		}
	// 	}
	// }
	// useEffect(() => {
	// 	getFieldValuesFrom(attributes);
	// }, [attributes]);
	const [errors, setErrors] = useState({});
	const isInitialLoadRef = useRef(true);
	const customSubmitExists = useRef();
	useEffect(() => {
		if (previewAttributes?.length) {
			setAttributes(previewAttributes);
		}
	}, [previewAttributes]);

	useEffect(() => {
		const fieldOpts = findElementIds(attributes);
		const vehiclePath = fieldOpts?.find((_) => _.value.includes("vehicleNumber"))?.value || "";
		const vehicleField = findTargetElement(vehiclePath, attributes);
		if (!vehicleField || !isInitialLoadRef.current || !attributes?.length || !vehicleField?.values?.length || !checkoutData) return;

		const { vehicleNumber } = checkoutData;
		handleDropdownChange({ ...vehicleField, value: vehicleNumber });
		isInitialLoadRef.current = false;
	}, [attributes, checkoutData]);

	useEffect(() => {
		const tripField = attributes.find((_) => _._id == "tripId");
		if (!tripField || !isInitialLoadRef.current || !attributes?.length || !tripField?.values?.length || !checkoutData) return;
		const { trip_counter } = checkoutData;
		setInitialValue(tripField, trip_counter);
		isInitialLoadRef.current = false;
	}, [attributes, checkoutData]);

	const setInitialValue = (field, inputValue = "") => {
		const { _id, api, apiMethod, apiParameters, apiDataReturnKey = "data", apiBindingKey, apiBindingValue, suffix = "", extraValue = [], domain } = field;
		let payload;
		let authInfo = { auth: field.auth, authMethod: field.authMethod, authParams: field.authParams };
		if (apiMethod.toLowerCase() == "get") {
			payload = apiParameters?.map((_) => `${encodeURIComponent(_.label)}=${encodeURIComponent(_.value)}`).join("&");
		} else {
			payload = {};
			apiParameters?.forEach((_) => {
				payload[_.label] = _.value == "onSearch" ? inputValue : _.value == "checkpointId" ? checkpointId : _.value;
			});
		}
		customAPI(payload, api, apiMethod, domain, authInfo).then((res) => {
			if (!res.error) {
				let _data = res[apiDataReturnKey] || [];
				updateAttributes(_id, _data);
				handleDropdownChange({ ...field, value: inputValue, values: _data });
			} else {
				handleDropdownChange({ ...field, value: "" });
			}
		});
	};

	function updateAttributes(id, data = "", type, index = null) {
		setAttributes((old) => old.map((attr) => setNestedAttr(attr, id, data, type, index)));
	}

	function fetchSourceDropdownData(field, inputValue = "", cb = null) {
		const { _id, api, apiMethod, apiDataReturnKey = "data", domain } = field;
		let payload = getAPIPayload(field, "api", { onSearch: inputValue, checkpointId });
		let authInfo = { auth: field.auth, authMethod: field.authMethod, authParams: field.authParams };
		customAPI(payload, api, apiMethod, domain, authInfo).then((res) => {
			if (!res.error) {
				let _data = res[apiDataReturnKey] || [];
				updateAttributes(_id, _data);
				updateAttributes(_id, false, "isSearching");
				if (cb) cb(_data);
			} else {
				updateAttributes(_id, []);
				updateAttributes(_id, false, "isSearching");
			}
		});
	}

	function getAPIPayload(field, payloadType, otherInput = {}) {
		let payload;
		const { apiMethod = "post", apiParameters = [], targetAPIMethod = "post", targetParameters = [], value = "", quickAddAPIMethod = "post", quickAddParameters = [] } = field;

		let params, method;
		switch (payloadType) {
			case "api":
				params = apiParameters;
				method = apiMethod.toLowerCase();
				break;
			case "target":
				params = targetParameters;
				method = targetAPIMethod.toLowerCase();
				break;
			case "quickAdd":
				params = quickAddParameters;
				method = quickAddAPIMethod.toLowerCase();
				break;
		}

		params.forEach((_) => {
			let paramValue = "";
			if (previewAttributes && _.sampleValue) {
				paramValue = _.sampleValue;
			} else if (_.value in otherInput) {
				// typically, the payload is generated based on the attribute's config
				// but sometimes we want to give specific data as well
				// for eg when user types in something in a dropdown and we want to send that input in the payload, or when we want to send checkpointId or any other use-case specific data
				// eg : otherInput = {onSearch : "userSearchValue", checkpointId: "1234"};
				paramValue = otherInput[_.value];
			} else if (typeof _.value === "string" && _.value.includes("$target")) {
				// eg: "$target.vehicle_section-vehicle_no#self"
				let targetPath = _.value.split("#")[0].split(".").splice(1).join(".");
				// eg: "vehicle_section-vehicle_no"
				let key = _.value.split("#").pop().toString();
				// eg: "self" || undefined || "" || "$self._id"
				let targetElm = findTargetElement(targetPath, attributes);
				if (targetElm) {
					if (key.includes("$self")) {
						paramValue = getPathValue(getSelf(targetElm), key.split(".").slice(1).join("."));
					} else {
						paramValue = targetElm.value;
					}
				}
			} else if (typeof _.value === "string" && _.value.includes("$self")) {
				paramValue = getPathValue(getSelf(field), _.value.split(".").slice(1).join("."));
			} else if (_.value == "self") {
				paramValue = value;
			} else {
				paramValue = _.value;
			}

			addToPayload(_.label, paramValue);
		});

		switch (method) {
			case "get":
				payload = "/?" + payload.join("&");
				break;
			case "get_uri":
				payload = "/" + payload;
				break;
			default:
				break;
		}
		return payload;
		function addToPayload(key = "", value) {
			switch (method) {
				case "get":
					if (!payload) payload = [];
					payload.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
					break;
				case "get_uri":
					if (!payload) payload = "";
					payload += encodeURIComponent(value);
					break;
				default:
					if (!payload) payload = {};
					payload[key] = value;
					break;
			}
		}
	}

	function setElement(element, data, type, index) {
		let new_target = element.split("-");
		updateAttributes(new_target.pop(), data, type, index);

		// the following is for the case when we have to also set the value of the target dropdown along with just setting its options

		// if (value) {
		// 	let valueCode = target.values.find((el) => el[target.apiBindingKey || "label"] == value);
		// 	if (valueCode) {
		// 		valueCode = valueCode[target.apiBindingValue || "value"];
		// 	}
		// 	target["value"] = valueCode;
		// }
	}

	function handleOnChangeSet(field, responseData) {
		let updates = calculateTarget(field, responseData);
		// console.log(updates);
		updatesRef.current = updates;
		// flushSync(() => {
		for (let { key, value } of updates) {
			setElement(key, value, "value");
		}
		setTargetUpdated(true);
		// });
	}

	function calculateTarget(field, data) {
		const { onChangeSet, apiBindingValue, type, values, value } = field;

		let updates = [];

		for (let key in onChangeSet) {
			let _value;
			let keyArr = [];
			if (onChangeSet[key].includes("$")) {
				keyArr = onChangeSet[key].split(".");
				if (keyArr[0] == "$response") {
					_value = data;
				} else {
					if (type == "select" && values?.length) {
						if (Array.isArray(value)) {
							_value = values.filter((el) => value.find((id) => id == el[apiBindingValue || "value"]));
						} else _value = values.find((el) => el[apiBindingValue || "value"] == value);
					} else {
						_value = value;
					}
				}
				keyArr = keyArr.slice(1);
			} else {
				if (data) {
					_value = data;
					keyArr = field.onChangeSet[key].split(".");
				} else {
					_value = onChangeSet[key];
				}
			}

			for (let key of keyArr) {
				if (_value) {
					_value = Array.isArray(_value) ? _value.map((_) => _[key]) : _value[key];
				}
			}
			// console.log({key, _value})
			if (_value != undefined) {
				updates.push({
					key: key,
					value: _value,
					type,
				});
			}
		}

		return updates;
	}
	const [targetUpdated, setTargetUpdated] = useState(false);
	const updatesRef = useRef([]);
	useEffect(() => {
		// here we are getting the updated state attribtues
		// console.log("targetUpdated", attributes);
		if (targetUpdated) {
			for (let { key, value, type } of updatesRef.current) {
				const targetAttr = findTargetElement(key, attributes);
				if (targetAttr) {
					if (targetAttr.type == "select" && targetAttr.dynamic) {
						// console.log(targetAttr)
						fetchSourceDropdownData(
							{ ...targetAttr, apiParameters: targetAttr.apiParameters.map((_) => (_.label == "searchBy" ? { ..._, value: targetAttr.apiBindingValue } : _)) },
							value,
							(values) => {
								// handleDropdownChange({ ...targetAttr, value, values });
							}
						);
						handleSourceSelectionCases({ ...targetAttr, value });
					} else {
						// console.log(targetAttr);
						// setTimeout(() => {
						// if (type == "fetch") {
						handleSourceSelectionCases({ ...targetAttr, value });
						// }
						// }, 120);
					}
				}
			}
			setTargetUpdated(false);
		}
	}, [targetUpdated]);
	function createPayloadFromAttr(attributes = []) {
		let payload = {};
		// console.log(attributes);
		for (let attr of attributes) {
			// console.log(attr);
			const { type, childrens, value, values, multiple, name, _id, conditionalView, conditionSatisfied, apiBindingValue, valueType, extraValue, valueFormat, selectableTable, multiselect } =
				attr;

			const key = name || _id;

			if (isVisible(conditionalView, conditionSatisfied)) {
				if (type == "grid_box") {
					payload = { ...payload, ...createPayloadFromAttr(childrens) };
				} else if (type == "group_addon" && childrens && childrens.length) {
					let childArr = [];
					value?.map((el, index) => {
						let newObj = {};
						for (let child of childrens) {
							if (isVisible(child.conditionalView, child.conditionSatisfied)) {
								newObj[child._id] = child.value ? child.value[index] : "";
								if (child.extraValue?.length) {
									for (let val of child.extraValue) {
										if (!child.values) child.values = [];
										let $self = child.values.find((el2) => el2[child.apiBindingValue || "value"] == child.value[index]);
										if ($self && val.valueType == "$self") {
											if (!val.label || val.label == "self") {
												let valObj = getFormattedValue($self, val.valueFormat);
												if (valObj) {
													newObj = { ...newObj, ...valObj };
												}
											} else {
												assignDeep(newObj, val.label, getFormattedValue($self, val.valueFormat));
											}
										} else {
											let label = val.label;
											let temp_obj = newObj;
											if (label.includes("$out")) {
												temp_obj = payload;
												label = label.split(".").slice(1).join(".");
											}
											if ((val.value + "").includes("$self")) {
												let key = val.value.split(".").slice(1).join(".");
												assignDeep(temp_obj, label, getPathValue($self, key));
											} else if ((val.value + "").includes("$data")) {
												let key = val.value.split(".").slice(1).join(".");
												// assignDeep(temp_obj, label, getPathValue(dynamicData, key, index));
											} else {
												assignDeep(temp_obj, label, val.value == "self" ? child.value[index] : val.value);
											}
										}
									}
								}
							}
						}
						childArr.push(newObj);
					});
					assignDeep(payload, key, childArr);
				} else if (type == "file") {
					assignDeep(payload, key, multiple ? values : value);
				} else if (type == "submit") {
					let thirdPartyPayload = getAPIPayload(attr, "api");
					assignDeep(payload, key, thirdPartyPayload);
				} else if (type == "table" && selectableTable) {
					let val;
					if (multiselect) {
						val = (value || []).map((i) => {
							let obj = removeEmptyKeysFromObj({ ...(values[i] || {}) });
							return obj;
						});
					} else {
						val = removeEmptyKeysFromObj(value);
					}
					assignDeep(payload, key, val);
				} else if (!["heading", "paragraph"].includes(type)) {
					let $self; // this refers to the object which holds the entire data of a dropdown selection. For eg: for a vehicle dropdown, $self will refer to the vehicle object from the backend;
					if (values && Array.isArray(values)) {
						if (value && Array.isArray(value)) {
							$self = values.filter((el) => value.includes(el[apiBindingValue || "value"]));
						} else {
							$self = values.find((el) => el[apiBindingValue || "value"] == value);
						}
					}

					//first two are for dropdown selection,

					if ($self && extraValue?.length) {
						for (let val of extraValue) {
							if ($self && val.valueType == "$self") {
								assignDeep(payload, val.label, getFormattedValue($self, val.valueFormat));
							} else {
								if (val.value.includes("$self")) {
									let key = val.value.split(".").slice(1).join(".");
									assignDeep(payload, val.label, getPathValue($self, key));
								} else {
									assignDeep(payload, val.label, val.value);
								}
							}
						}
					} else if ($self && valueType == "$self") {
						assignDeep(payload, key, getFormattedValue($self, valueFormat));
					} else {
						assignDeep(payload, key, value);
					}
				}
			}
		}
		return payload;
	}

	function getConditionalDepValue(key, attr) {
		if ((key + "").includes("$self")) {
			let $self = attr.value;
			if (attr.type == "select" && attr.values?.length) {
				$self = attr.values.find((el) => el[attr.apiBindingValue || "value"] == attr.value);
			}

			let keyArr = key.split(".").slice(1);
			for (let key of keyArr) {
				if ($self && $self[key]) {
					$self = $self[key];
				} else {
					$self = "";
					break;
				}
			}
			return $self;
		}

		return key == "self" ? attr.value : key;
	}
	function getFormattedValue($self, valueFormat) {
		if (!valueFormat.length) return $self;

		if (Array.isArray($self)) {
			return $self.map((val) => getFormatObj(val));
		} else {
			return getFormatObj($self);
		}
		function getFormatObj(obj) {
			let res = {};
			for (let vf of valueFormat) {
				res[vf.label] = getPathValue(obj, vf.value);
			}
			return res;
		}
	}

	const handleDropdownSearch = useCallback(
		debounce((component, inputValue) => {
			fetchSourceDropdownData(component, inputValue);
		}, 500),
		[]
	);

	// index in case of group_addon
	const handleInputChange = async (component, data = "", index = null) => {
		const { _id, searchableDropdown, type } = component;
		if (type != "select") {
			if (type === "file") {
				const filedata = await base64Convert(data.target.files[0]);
				// console.log('File selected', filedata);
				updateAttributes(_id, filedata, "value", index);
			} else {
				updateAttributes(_id, data, "value", index);
				handleSourceSelectionCases({ ...component, value: data });
			}
		}
		if (searchableDropdown) {
			updateAttributes(_id, [], "values"); //make options array empty
			updateAttributes(_id, true, "isSearching");

			handleDropdownSearch({ ...component }, data);
			handleDynamicCalculation(component, index);
		}
	};

	function getElementValue(targetValue, field, parentIndex) {
		let value_return = "";
		if ((targetValue + "").includes("$target")) {
			let target = targetValue.split("#")[0].split(".").splice(1).join(".");
			let key = targetValue.split("#").pop();
			if (target) {
				let value = "";
				let targetElm = findTargetElement(target, attributes);
				if (targetElm) {
					if ((key + "").includes("$self")) {
						let $self = "";
						if (targetElm.type == "select" && targetElm.values?.length) {
							$self = targetElm.values.find(
								(el) => el[targetElm.apiBindingValue || "value"] == (parentIndex !== "" && parentIndex != undefined ? targetElm.value[parentIndex] : targetElm.value)
							);
						} else {
							$self = targetElm.value ? (parentIndex !== "" && parentIndex != undefined ? targetElm.value[parentIndex] : targetElm.value) : "";
						}
						let keyArr = key.split(".");
						keyArr = keyArr.slice(1);
						value = $self;
						for (let key of keyArr) {
							if (value && value[key]) {
								value = value[key];
							} else {
								value = "";
								break;
							}
						}
					} else {
						value = targetElm.value ? (parentIndex !== "" && parentIndex != undefined ? targetElm.value[parentIndex] : targetElm.value) : "";
					}
				}
				value_return = value;
			}
		} else if ((targetValue + "").includes("$self")) {
			let $self = "";
			let searchValue = parentIndex !== "" ? field.value[parentIndex] : field.value;
			if (field.type == "select" && field.values && field.values.length) {
				$self = field.values.find((el) => el[field.apiBindingValue ? field.apiBindingValue : "value"] == searchValue);
			} else {
				$self = searchValue;
			}
			let value = $self;
			let keyArr = targetValue.split(".");
			keyArr = keyArr.slice(1);
			for (let key of keyArr) {
				if (value && value[key]) {
					value = value[key];
				} else {
					value = "";
					break;
				}
			}
			value_return = value;
		} else if ((targetValue + "").includes("$data")) {
			// let $self = this.dynamicData;
			// let key = targetValue.split(".").slice(1).join(".");
			// // let searchValue=(parentIndex!=='' ? field.value[parentIndex]: field.value)
			// // if(field.type=='select' && field.values && field.values.length){
			// //   $self=field.values.find(el=>el[field.apiBindingValue?field.apiBindingValue:"value"]==searchValue)
			// // }
			// // else{
			// //   $self=searchValue
			// // }
			// let value = $self;
			// let keyArr = targetValue.split(".");
			// keyArr = keyArr.slice(1);
			// for (let key of keyArr) {
			// 	if (key.includes("#")) {
			// 		let tempkey = key.split("#")[0];
			// 		value = value[tempkey];
			// 		if (parentIndex !== "") {
			// 			key = parentIndex;
			// 		} else {
			// 			key = key.split("#")[1];
			// 		}
			// 	}
			// 	if (value && value[key]) {
			// 		value = value[key];
			// 	} else {
			// 		value = "";
			// 		break;
			// 	}
			// }
			// value_return = value;
		} else {
			value_return = targetValue == "self" ? (parentIndex !== "" && parentIndex != undefined ? field.value[parentIndex] : field.value) : targetValue;
		}
		return value_return;
	}

	/**
	 *
	 */
	function handleDynamicCalculation(field, index = null) {
		const { dynamiCalculation } = field;
		if (dynamiCalculation?.length) {
			try {
				for (let cal of dynamiCalculation) {
					if (cal.formula) {
						let equationStr = "";
						let calculate = true;
						for (let equation of cal.formula) {
							let eq_value = getElementValue(equation.fieldValue, field, null);
							if (!eq_value && equation.defaultValue) {
								eq_value = equation.defaultValue;
							}
							if (equation.type == "static") {
								equationStr += "`" + eq_value + "`";
							} else if (equation.type == "number") {
								if (eq_value === "" || Number.isNaN(Number(eq_value))) {
									calculate = false;
									break;
								} else {
									let v = Number.isNaN(Number(eq_value)) ? 0 : eq_value || 0;
									equationStr += `(${v})`;
								}
							} else if (equation.type == "date") {
								if (eq_value) {
									equationStr += new Date(eq_value).getTime();
								} else {
									calculate = false;
									break;
								}
							} else if (equation.type == "time") {
								if (eq_value) {
									eq_value = timeToTimestamp(eq_value) + "";
									equationStr += eq_value;
								} else {
									calculate = false;
									break;
								}
							} else {
								equationStr += eq_value;
							}
						}
						let value = calculate ? (cal.static ? equationStr : eval(equationStr)) : "";
						if (cal.formatFunction) {
							if (value !== undefined && value !== null && value !== "") {
								value = eval(cal.formatFunction + `(${value})`);
							}
						} else {
							value = value != undefined && !cal.static ? convertNumber(value) : value || "";
						}
						setElement(cal.targetElement, value, cal.targetKey || "value", index);
						if (cal.targetElement) {
							const targetElem = findTargetElement(cal.targetElement, attributes);
							handleSourceSelectionCases({ ...targetElem, value });
						}
					}
				}
			} catch (error) {
				// console.log(error);
			}
		}
	}
	// console.log("out",attributes)

	function handleSourceSelectionCases(field) {
		const { value, bindTarget, setTargetValue, conditionalDependent, targetAPI, targetDomain, targetElement, targetAPIMethod, targetParameters, onChangeSet = {}, _id, domain, type } = field;
		// usecase #1 : on source selection -> fill options of a target
		// console.log({setTargetValue, targetAPI, onChangeSet})
		if (bindTarget || (setTargetValue && targetAPI)) {
			let payload = getAPIPayload(field, "target");
			// console.log({ targetElement });
			let authInfo = { auth: field.targetAuth, authMethod: field.targetAuthMethod, authParams: field.targetAuthParams };
			customAPI(payload, targetAPI, targetAPIMethod, targetDomain || domain, authInfo).then((res) => {
				if (!res.error) {
					if (Object.keys(onChangeSet).length) {
						try {
							handleOnChangeSet(field, res.data);
						} catch (e) {
							console.error(e);
						}
					} else {
						setElement(targetElement, res.data, "values"); //targetValue -> to set the value of the targetDropdown
						const newApiParams = Object.keys(payload).map((key) => {
							return { label: key, value: payload[key] };
						});
						// add the payload to target's api parameters
						// eg : payload = {productCategoryId: "abc"}; and targetElement is "product"
						// so when product is searched, we want "productCategoryId: "abc"" to be passed in the payload
						setAttributes((old) =>
							old.map((attr) => {
								if (attr._id == targetElement) {
									return { ...attr, apiParameters: [...attr.apiParameters, ...newApiParams] };
								}
								return attr;
							})
						);
					}
				} else {
					setElement(targetElement, [], "values"); //targetValue -> to set the value of the targetDropdown

					// in case we're fetching data using a button, show err msg
					if (type == "fetch") {
						if (Object.keys(onChangeSet).length) {
							try {
								// console.log(onChangeSet, onChangeSet);
								handleOnChangeSet(
									field,
									Object.entries(onChangeSet).reduce((res, curr) => {
										res[curr[0]] = "";
										return res;
									}, {})
								);
							} catch (e) {
								console.error(e);
							}
						}
						toastMessage(false, res.message);
					}
				}
			});
		}
		// usecase #2: on source selection -> set value of targets
		else if (setTargetValue && onChangeSet) {
			try {
				handleOnChangeSet(field);
			} catch (error) {
				console.error(error);
			}
		}

		// usecase #3: on source selection -> change attributes of target (show/hide, readonly, disbabled, etc)
		if (conditionalDependent?.length) {
			for (let condition of conditionalDependent) {
				let { target: targetPath, actions, onValue } = condition;

				if (targetPath) {
					const targetAttr = findTargetElement(targetPath, attributes);
					if (targetAttr && actions?.length) {
						// console.log({ onValue, value });
						let conditionCheck;
						// === here is crucial as 0 == "" -> true
						if (onValue == "*" && value !== "") {
							conditionCheck = true;
						} else if (Array.isArray(value)) {
							conditionCheck = value.some((v) =>
								onValue
									.split(",")
									.map((_) => _.trim())
									.includes(v)
							);
						} else {
							conditionCheck = onValue
								.split(",")
								.map((_) => _.trim())
								.includes(value);
						}
						for (let act of actions) {
							if (act.key) {
								// // lynkit.io usecase, to be implemented later
								// if (conditionCheck && act.other_dependency?.length) {
								// 	for (let dp of act.other_dependency) {
								// 		const [dependentElem] = attributes.map((attr) => findNestedAttr(attr, dp.target?.split("-"))).filter(Boolean);
								// 		if (dependentElem.value != dp.value) {
								// 			conditionCheck = false;
								// 			break;
								// 		}
								// 	}
								// }
								if (conditionCheck) {
									if (act.hasOwnProperty("value")) {
										let elemValue = getConditionalDepValue(act.value, field);
										if (!targetAttr.value || (targetAttr.value && act.key != "value")) {
											updateAttributes(targetAttr._id, elemValue, act.key);
										}
									}
								} else if (act.hasOwnProperty("elseValue")) {
									let elemValue = getConditionalDepValue(act.elseValue, field);
									if (!targetAttr.value || (targetAttr.value && act.key != "value")) {
										updateAttributes(targetAttr._id, elemValue, act.key);
									}
								}
							}
						}
					}
				}
			}
		}

		handleDynamicCalculation(field);
	}

	const handleDropdownChange = (field, index = null) => {
		const { value, _id } = field;
		updateAttributes(_id, value, "value", index);
		handleSourceSelectionCases(field);
	};

	if (isLoading) return <Loader size="2rem" />;
	if (templateDetailError) return <DataNotFound />;

	const handleFormSubmit = (evt) => {
		/**
		 * checkout : undefined | true | false
		 * evt : undefined | "draft"
		 */
		let { vehicleId, checkout, ...data } = createPayloadFromAttr(attributes);
		vehicleId = vehicleId ? vehicleId : checkoutData?.vehicleId;
		const payload = { vehicleId, data, checkout: evt == "draft" ? false : checkout != undefined ? checkout : checkoutData ? true : false };
		onSubmit(payload);
	};
	const handleFormValidation = (e, type) => {
		e.preventDefault();
		let errors = {};
		validateFields(attributes, errors);
		// console.log(errors);

		if (Object.keys(errors).length) {
			setErrors(errors);
		} else {
			setErrors({});
			handleFormSubmit(type);
		}
	};

	const onQuickAddSave = (field, quickAddValue, handleClear) => {
		const { quickAddAPI, quickAddAPIMethod, domain, apiBindingKey = "label", apiBindingValue = "value", values, _id } = field;
		if (quickAddAPI) {
			const payload = getAPIPayload({ ...field, value: quickAddValue }, "quickAdd");
			let authInfo = { auth: field.quickAddAuth, authMethod: field.quickAddAuthMethod, authParams: field.quickAddAuthParams };
			// console.log(payload);
			customAPI(payload, quickAddAPI, quickAddAPIMethod, domain, authInfo).then((res) => {
				// console.log(res);
				if (res.error) {
					toastMessage(false, res.message);
				} else {
					const result = res.data || {
						[apiBindingKey]: quickAddValue,
						[apiBindingValue]: apiBindingKey == apiBindingValue ? quickAddValue : new Date().getTime().toString(),
					};
					updateAttributes(_id, [result, ...values], "values");
					updateAttributes(_id, result[apiBindingValue], "value");
					handleClear();
				}
			});
		} else {
			const result = {
				[apiBindingKey]: quickAddValue,
				[apiBindingValue]: apiBindingKey == apiBindingValue ? quickAddValue : new Date().getTime().toString(),
			};
			updateAttributes(_id, [result, ...values], "values");
			updateAttributes(_id, result[apiBindingValue], "value");
			handleClear();
		}
	};

	const handleFetchBtnClick = (field) => {
		handleSourceSelectionCases(field);
	};
	const handleTableRowSelect = (field, row) => {
		const { value, _id, values, multiselect } = field;
		// for field type "table": the rows of the table are in "values",

		let val = multiselect ? [...(Array.isArray(value) ? value : [])] : value;

		if (row != undefined) {
			// select this particular row
			// so when a row is selected, save the index in the "value"
			if (multiselect && Array.isArray(val)) {
				if (val.find((v) => compareObj(v, row))) {
					val = val.filter((v) => compareObj(v, row));
				} else {
					val = [...val, row];
				}
				// val = val.includes(index) ? val.filter((v) => v != index) : [...val, index];
			} else {
				if (typeof val === "object" && !Array.isArray(val) && val !== null && Object.keys(val).length) {
					val = {};
				} else {
					val = { ...row };
				}
				handleSourceSelectionCases({ ...field, value: val });
			}
			updateAttributes(_id, val, "value");
		} else {
			if (multiselect) {
				// select all rows ++
				// when all rows are selected, we will simply add all indices of "values" into "value"
				val = val.length == values?.length ? [] : values.map((_, i) => i);
				updateAttributes(_id, val, "value");
			}
		}
	};
	function recursiveRender(attributes, isNested = false) {
		return attributes.map((component, i) => {
			const { conditionalView, conditionSatisfied, type, showBorder, showLabel, childrens, label, hideLabel, _id, value, filterOnly } = component;
			// field only needed as a filter
			if (filterOnly) return null;
			switch (type) {
				case "grid_box":
					return isVisible(conditionalView, conditionSatisfied) ? (
						<fieldset
							className={showBorder ? "dynamic_fieldset" : ""}
							style={{
								gridColumn: !isNested ? "1/-1" : "auto",
								display: "grid",
								gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
								gap: "0.5rem",
								border: showBorder ? "1px solid var(--input-border-hover-color)" : "none",
								paddingTop: !showBorder ? "none" : !showLabel ? "1.5rem" : "1rem",
								padding: showBorder ? "1rem 0.5rem" : "none",
								borderRadius: "10px",
								color: "#aaabaf",
							}}
							key={i}
						>
							{/* Heading of the gridbox. Only visible if the label is present and showBorder is   active */}
							{showLabel && <legend style={{ fontSize: "19px", padding: "1px 3px", float: "none", width: "auto", marginBottom: "auto", marginLeft: "12px" }}>{label}</legend>}

							{recursiveRender(childrens, true)}
						</fieldset>
					) : null;
				case "group_addon":
					// value here will be an array of objects
					// each object will contain key-value pairs of the number of input
					// eg: the group addon component should have one Driver dropdwon and one driver mobile number input
					// so the value = [{dr1: "", dr2:""}];
					// dr1 and dr2 are the references to the actual inputs inside the childrens array (_id or name)
					// the reason for having this Value Array is only to keep track of the groups. When the plus icon, is clicked, another entry is added to the value array.

					const onAdd = () => {
						updateAttributes(_id, [...value, value[0]], "value");
						childrens.forEach((child) => {
							if (!Array.isArray(child.value)) {
								updateAttributes(child._id, [""], "value");
							} else {
								updateAttributes(child._id, [...child.value, ""], "value");
							}
						});
					};

					const onRemove = (index) => {
						let newV = [...value];
						newV.splice(index, 1);
						updateAttributes(_id, newV, "value");
						childrens.forEach((child) => {
							// console.log({v: child.value})
							if (Array.isArray(child.value) && child.value[index] != undefined) {
								updateAttributes(
									child._id,
									child.value.filter((_, i) => index != i),
									"value"
								);
							}
						});
					};

					return isVisible(conditionalView, conditionSatisfied) ? (
						<div key={i} style={{ gridColumn: "1/-1", display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }}>
							{!hideLabel ? <InputLabel>{label}</InputLabel> : null}
							{value.map((_, i) => {
								return (
									<div
										key={i}
										style={{
											gridColumn: "1/-1",
											display: "grid",
											gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
											gap: "0.5rem",
										}}
									>
										{childrens.map((child, j) => {
											if (isVisible(child.conditionalView, child.conditionSatisfied))
												return (
													<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }} key={j}>
														<CustomInput
															component={child}
															index={i}
															autoFillOnFocus={autoFillOnFocus}
															errors={errors}
															fetchSourceDropdownData={fetchSourceDropdownData}
															onDropdownChange={(newVal) => {
																handleDropdownChange({ ...child, value: newVal }, i);
															}}
															onInputChange={(e) => {
																// console.log(component._id, e.target?.value, i);
																if (child.type === "file") {
																	// console.log('file input change', e.target.value)
																	handleInputChange(child, e, i);
																} else {
																	handleInputChange(child, e.target.value, i);
																}
																// change the value in the main attributes array
															}}
															onQuickAddSave={onQuickAddSave}
															onFetchBtnClick={handleFetchBtnClick}
														/>
														<Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: "left", mt: "5px", ml: "5px" }}>{errors[child._id] || ""}</Typography>
													</div>
												);
											return null;
										})}
										<AddRemove
											list={value}
											filterMethod={(c, i) => {
												const hasValidValue = childrens.some((child) => child.value && Array.isArray(child.value) && child.value[i] != undefined && child.value[i] != "");
												return !hasValidValue;
											}}
											onAdd={onAdd}
											onRemove={onRemove}
											index={i}
											outerIndex={i}
										/>
									</div>
								);
							})}
						</div>
					) : null;
				default:
					if (type === "submit") {
						customSubmitExists.current = true;
					}
					// console.log(errors)
					return isVisible(conditionalView, conditionSatisfied) ? (
						<div style={{ gridColumn: type == "table" ? "1/-1" : "auto", display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }} key={i}>
							<CustomInput
								component={component}
								autoFillOnFocus={autoFillOnFocus}
								errors={errors}
								fetchSourceDropdownData={fetchSourceDropdownData}
								onDropdownChange={(newVal) => {
									handleDropdownChange({ ...component, value: newVal });
								}}
								onInputChange={(e) => {
									if (component.type === "file") {
										// console.log('file input change', e.target.value)
										handleInputChange(component, e);
									} else {
										handleInputChange(component, e.target.value);
									}
									// change the value in the main attributes array
								}}
								onQuickAddSave={onQuickAddSave}
								handleFormSubmit={handleFormSubmit}
								onClose={onClose}
								btnText={btnText}
								isSubmitDisabled={isSubmitDisabled}
								isSaveDraft={isSaveDraft}
								onFetchBtnClick={handleFetchBtnClick}
								onTableRowSelect={handleTableRowSelect}
							/>
							<Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: "left", mt: "5px", ml: "5px" }}>{errors[component._id] || ""}</Typography>
						</div>
					) : null;
			}
		});
	}
	function filterRenderer(attributes) {
		return attributes.map((component, i) => {
			return (
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }} key={i}>
					<CustomInput
						component={component}
						autoFillOnFocus={autoFillOnFocus}
						errors={errors}
						fetchSourceDropdownData={fetchSourceDropdownData}
						onDropdownChange={(newVal) => {
							handleDropdownChange({ ...component, value: newVal });
							onFilterChange(component._id, newVal);
						}}
						onInputChange={(e) => {
							if (component.type === "file") {
								// console.log('file input change', e.target.value)
								handleInputChange(component, e);
							} else {
								handleInputChange(component, e.target.value);
							}
							onFilterChange(component._id, e.target.value);
							// change the value in the main attributes array
						}}
						onQuickAddSave={onQuickAddSave}
						handleFormSubmit={handleFormSubmit}
						onClose={onClose}
						btnText={btnText}
						isSubmitDisabled={isSubmitDisabled}
						isSaveDraft={isSaveDraft}
						onFetchBtnClick={handleFetchBtnClick}
						onTableRowSelect={handleTableRowSelect}
					/>
					<Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: "left", mt: "5px", ml: "5px" }}>{errors[component._id] || ""}</Typography>
				</div>
			);
		});
	}
	function clearFilters() {
		setAttributes((o) => o.map((_) => ({ ..._, value: "" })));
	}
	// console.log(attributes);
	/**
	 * Our dynamic form template is of the form Array of Objects. Each Object represents a form input.
	 * By Default, we want the form inputs to render SIDE-BY-SIDE, EQUAL-SIZED, and RESPONSIVE.
	 * eg: template = [child, child, child, child] => <child child child child>
	 * However, when an Object of type GRID_BOX, GROUP_ADDON, is encounter, we want it to occupy full width and render its children inside
	 * eg: template = [child, gridbox: [child, child], child, child] => <child>
	 * 																	<child child>
	 * 																	<child child>
	 */

	return (
		<>
			{renderHeader()}
			<form
				className={`dynamic-form ${extraClass}`}
				onSubmit={handleFormValidation}
				onKeyDownCapture={(e) => {
					if (e.key == "Enter" || e.keyCode == "13") e.preventDefault();
				}}
				style={{ margin: "1.5rem 0 0.5rem 0" }}
			>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(auto-fit, minmax(270px, 1fr))",
						gap: "0.5rem",
						alignItems: "center",
					}}
				>
					{filterOnly ? filterRenderer(attributes) : recursiveRender(attributes)}
					{renderAction(clearFilters, onSubmit)}
				</div>
				{!customSubmitExists.current && !noBtn && (
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "1rem" }}>
						<Button
							onClick={
								isSaveDraft
									? () => {
											handleFormSubmit("draft");
									  }
									: onClose
							}
							text={btnText}
							style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }}
						/>
						<Button type="submit" text={btn2Text} style={{ ...buttonClasses.lynkitOrangeFill, width: "fit-content" }} disabled={isSubmitDisabled} />
					</div>
				)}
			</form>
		</>
	);
}
function setNestedAttr(attribute, _id, value, key = "values", index = null) {
	// console.log('at nested', attribute);
	if (attribute._id == _id) {
		// group_addon case
		if (index != null && key == "value") {
			let newVal;
			if (Array.isArray(attribute.value)) {
				newVal = [...attribute.value];
				if (newVal[index] == undefined) {
					newVal[index] = "";
				}
			} else {
				newVal = new Array(index + 1).fill(null);
			}
			newVal.splice(index, 1, value);
			return { ...attribute, value: newVal };
		} else {
			return { ...attribute, [key]: value };
		}
	}
	if (attribute.childrens?.length) {
		return { ...attribute, childrens: attribute.childrens.map((attr) => setNestedAttr(attr, _id, value, key, index)) };
	}
	return attribute;
}
function findTargetElement(targetPath = "", attributes) {
	const [targetAttr] = attributes
		.map((attr) => findNestedAttr(attr, targetPath.split("-")))
		.flat(Infinity)
		.filter(Boolean);
	return targetAttr;
}
function findNestedAttr(attribute, keys) {
	const [head, ...tail] = keys;
	// nested level
	if (Array.isArray(attribute)) {
		return attribute.map((attr) => findNestedAttr(attr, keys));
	}
	// first level
	if (attribute._id == head) {
		// if the head matches, then see if the key is last or is there further nesting
		if (keys.length == 1) {
			if (Array.isArray(attribute)) {
				return attribute.find((_) => _._id == head);
			} else return attribute._id == head ? attribute : null;
		} else if (attribute.childrens?.length) {
			return findNestedAttr(attribute.childrens, tail);
		}
	}
	return null;
}

function timeToTimestamp(timeString, add = false) {
	// Split the time string into hours and minutes
	const [hours, minutes] = timeString.split(":").map(Number);

	// Create a new Date object for today's date
	if (add) {
		const now = new Date();
		now.setHours(hours, minutes, 0, 0); // Set the hours and minutes
		// Return the timestamp
		return now.getTime();
	} else {
		let timestamp = 0;
		timestamp += Number(hours) * 60 * 60;
		timestamp += Number(minutes) * 60;

		// Return the timestamp
		return timestamp * 1000;
	}
}
function convertNumber(num) {
	if (num) {
		return Number.isNaN(Number(num)) ? num : Number(Number(num).toFixed(2));
	} else {
		return 0;
	}
}
export function assignDeep(obj, key, value) {
	try {
		if (key && value != undefined) {
			let keys = (key + "").split(".");
			if (keys.length > 1) {
				keys.forEach((key, index) => {
					if (index === keys.length - 1) {
						// assign value on the lowest level
						obj[key] = value;
					} else {
						if (!obj[key]) obj[key] = {};
						obj = obj[key];
					}
				});
			} else {
				obj[key] = value;
			}
		}
	} catch (e) {
		// console.log("Error in payload creation", e);
	}
	return true;
}
function findElementIds(fields, prefix = "") {
	const currentLevelIds = fields.filter(Boolean).map((_) => ({ label: prefix ? `${prefix}-${_._id}` : _._id, value: prefix ? `${prefix}-${_._id}` : _._id }));
	fields.forEach((field) => {
		if (field.childrens?.length) {
			currentLevelIds.push(...findElementIds(field.childrens, prefix ? `${prefix}-${field._id}` : field._id));
		}
	});
	return currentLevelIds;
}

import { createContext, useContext, useEffect, useState } from "react";
import { useCheckpoints, useDynamicModules, useUserDetail, useUserPermissions } from "./hooks";
import { useLocation } from "react-router-dom";
import {
	DashboardIcon,
	AnalyticsIcon,
	DeviceIcon,
	TagIcon,
	UsersIcon,
	CompanyIcon,
	ProductIcon,
	ProductCategoriesIcon,
	SupplierIcon,
	VehicleIcon,
	TagMappingIcon,
	RoleIcon,
	GateOutIcon,
	GateInIcon,
	AccessIcon,
	BayIcon,
	DummyIcon,
	ReportIcon,
	WorkFlowIcon,
	ManageTripIcon,
	ParkingIcon,
	WeighbridgeIcon,
	ApprovalIcon,
	CheckpointTypeIcon,
	DynamicFormIcon,
	RightArrowIcon,
	ArrowDownIcon,
	DynamicModuleIcon,
	BallotIcon,
	DriverIcon,
} from "./icons";
import { capitalizeCamelCase, removeSpecialChars } from "./functions";

const TourContext = createContext();
export function TourProvider(props) {
	const [receivedGuidedTour, setReceivedGuidedTour] = useState(true);
	const value = [receivedGuidedTour, setReceivedGuidedTour];
	return <TourContext.Provider value={value} {...props} />;
}
export function useTour() {
	const context = useContext(TourContext);
	if (!context) {
		throw new Error("useTour must be within a TourProvider");
	}
	return context;
}

const SideBarItemsContext = createContext();
export function SideBarItemsProvider(props) {
	const { userDetail } = useUserDetail();
	const { userPermissions } = useUserPermissions();
	const { pathname } = useLocation();

	const checkpointItems = useCheckpointItems();
	const dynamicModuleItems = useDynamicModuleItems();
	let items = [
		{
			title: "Dashboard",
			Icon: DashboardIcon,
			link: "/dashboard_old",
			permission: userPermissions?.getDashboard?.value,
			current: ["/dashboard_old"].includes(pathname),
			children: [],
			class: "dashboard-module",
			description: "You can view analytics and various stats here",
		},
		{
			title: "Dashboard",
			Icon: DashboardIcon,
			link: "/dashboard",
			permission: userPermissions?.getDashboardV2?.value || userDetail?.userType === "superAdmin",
			current: ["/dashboard"].includes(pathname),
			children: [],
			class: "dashboard-module",
			description: "You can view analytics and various stats here",
		},
		{
			title: "Analytics",
			Icon: AnalyticsIcon,
			link: '/analytics',
			permission:userPermissions?.getDashboardV2?.value || userDetail?.userType === "superAdmin",
			current:['/analytics'].includes(pathname),
			children: [],
			class: "analytics-module",
			description: "You can view analytics and various stats here",

		},
		{
			title: "Devices",
			Icon: DeviceIcon,
			link: "/devices",
			permission: userPermissions?.getDevice?.value,
			current: ["/devices", "/devices/add-device"].includes(pathname),
			children: [],
			class: "device-module",
			description: "You can manage different Devices here.",
		},
		{
			title: "Tags",
			Icon: TagIcon,
			link: "/tags",
			permission: userPermissions?.getTag?.value,
			current: ["/tags"].includes(pathname),
			children: [],
			class: "tag-module",
			description: "You can manage RFID Tags here.",
		},
		{
			title: "Stray tags",
			Icon: TagMappingIcon,
			link: "/stray-tags",
			permission: userPermissions?.accessStrayTags?.value,
			current: ["/stray-tags"].includes(pathname),
			children: [],
			class: "unregister-tag-module",
			description: "Find stray tags scanned by RFID devices here",
		},
		{
			title: "Approval Configuration",
			Icon: TagMappingIcon,
			link: "/approval-configuration",
			permission: userDetail?.userType == "superAdmin",
			current: ["/approval-configuration"].includes(pathname),
			children: [],
			class: "approval-config-module",
			description: "Here you manage the approval configurations.",
		},
		{
			title: "Users",
			Icon: UsersIcon,
			link: "/users",
			permission: userPermissions?.getUsers?.value,
			current: ["/users"].includes(pathname),
			children: [],
			class: "users-module",
			description: "You can add your users here, giving them roles and relevant permissions",
		},
		{
			title: "Roles",
			Icon: RoleIcon,
			link: "/roles",
			permission: userPermissions?.getUserRoles?.value,
			current: ["/roles"].includes(pathname),
			children: [],
			class: "role-module",
			description: "You can manage roles for your users here.",
		},
		{
			title: "Companies",
			Icon: CompanyIcon,
			link: "/companies",
			permission: userPermissions?.getOrganizations?.value,
			current: ["/companies"].includes(pathname),
			children: [],
			class: "company-module",
			description: "You can manage your organizations here.",
		},
		{
			title: "Product Categories",
			Icon: ProductCategoriesIcon,
			link: "/product-categories",
			permission: userPermissions?.getProductCategory?.value,
			current: ["/product-categories"].includes(pathname),
			children: [],
			class: "product-categories-module",
			description: "Here you can manage your Product Categories.",
		},
		{
			title: "Products",
			Icon: ProductIcon,
			link: "/products",
			permission: userPermissions?.getProduct?.value,
			current: ["/products"].includes(pathname),
			children: [],
			class: "product-module",
			description: "Here you can manage your Products.",
		},
		{
			title: "Suppliers",
			Icon: SupplierIcon,
			link: "/suppliers",
			permission: userPermissions?.getSupplier?.value,
			current: ["/suppliers"].includes(pathname),
			children: [],
			class: "supplier-module",
			description: "Here you can manage your Suppliers.",
		},
		{
			title: "Reports",
			Icon: ReportIcon,
			link: "/reports",
			permission: userPermissions?.viewReport?.value,
			current: pathname.includes("/reports"),
			children: [],
			class: "report-module",
			description: "You can view your reports here.",
		},
		{
			title: "Driver Master",
			Icon: DriverIcon,
			link: "/driver-master",
			permission: userPermissions?.getDriver?.value,
			current: pathname.includes("/driver-master"),
			children: [],
			class: "driver-module",
			description: "You can manage your drivers here.",
		},
		{
			title: "Vehicle Master",
			Icon: VehicleIcon,
			link: "/vehicle-master",
			permission: userPermissions?.getVehicle?.value,
			current: pathname.includes("/vehicle-master"),
			children: [],
			class: "vehicle-module",
			description: "You can manage your vehicles here.",
		},
		{
			title: "Checkpoints",
			Icon: ReportIcon,
			link: "/create-checkpoint",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/create-checkpoint"),
			children: [],
			class: "checkpoints-master-module",
			description: "You can manage your checkpoints here.",
		},
		{
			title: "Checkpoint Types",
			Icon: CheckpointTypeIcon,
			link: "/checkpoint-types",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/checkpoint-types"),
			children: [],
			class: "checkpoint-type-module",
			description: "You can manage checkpoint types here.",
		},
		{
			title: "WorkFlow",
			Icon: WorkFlowIcon,
			link: "/workflow",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/workflow"),
			children: [],
			class: "workflow-module",
			description: "You can manage your workflows here.",
		},
		{
			title: "Manage Trips",
			Icon: ManageTripIcon,
			link: "/manage-trip",
			permission: userPermissions?.manageTrip?.value,
			current: pathname.includes("/manage-trip"),
			children: [],
			class: "manage-trip-module",
			description: "You can manage your trips here.",
		},
		{
			title: "Approval Management",
			Icon: ApprovalIcon,
			link: "/approval-management",
			permission: userDetail?.userRole == "bayOperator" || userPermissions?.manageApprovals?.value,
			current: pathname.includes("/approval-management"),
			children: [],
			class: "approval-management-module",
			description: "You can manage your approvals here.",
		},
		{
			title: "Dynamic Form",
			Icon: DynamicFormIcon,
			link: "/templates",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/templates"),
			children: [],
			class: "dynamic-form-module",
			description: "You can manage your dynamic forms here.",
		},
		{
			title: "Dynamic Module",
			Icon: DynamicModuleIcon,
			link: "/dynamic-module",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/dynamic-module"),
			children: [],
			class: "dynamic-module",
			description: "You can manage your dynamic modules here",
		},
		...dynamicModuleItems,
		...checkpointItems,
	].filter((_) => _.permission);
	// console.log(items);

	return <SideBarItemsContext.Provider value={items} {...props} />;
}

export function useSidebarItems() {
	const context = useContext(SideBarItemsContext);
	if (!context) {
		throw new Error("useSideItems must be within a SidebarItemsProvider");
	}
	return context;
}

function useCheckpointItems() {
	const { pathname } = useLocation();
	const { checkpoints, getCheckpoints } = useCheckpoints();
	const checkpointItems = categoriesCheckpointItems(checkpoints);
	const { userDetail } = useUserDetail();

	useEffect(() => {
		if (!userDetail || userDetail.userType == "superAdmin") return;
		getCheckpoints();
	}, [userDetail]);

	if (userDetail?.userType == "superAdmin") return [];

	const checkpointMenu = Object.keys(checkpointItems).map((ele, index) => {
		const iconMap = {
			gateOut: GateOutIcon,
			gateIn: GateInIcon,
			access: AccessIcon,
			bay: BayIcon,
			parking: ParkingIcon,
			weighbridge: WeighbridgeIcon,
		};
		if (checkpointItems[ele].length > 0) {
			return {
				title: capitalizeCamelCase(removeSpecialChars(ele)),
				Icon: iconMap[ele] || DummyIcon,
				link: null,
				permission: true,
				current: null,
				class: "checkpoint-module",
				description: "You can manage vehicle entries at your predefined locations here.",
				children: checkpointItems[ele].map((_) => {
					const iconMap = {
						gateOut: GateOutIcon,
						gateIn: GateInIcon,
						access: AccessIcon,
						bay: BayIcon,
						parking: ParkingIcon,
						weighbridge: WeighbridgeIcon,
					};
					return {
						title: _.displayName,
						Icon: iconMap[_.checkpointType] || DummyIcon,
						link: `/checkpoints/${_.checkpointType}/${_.checkpointNumber}`,
						permission: true,
						current: [`/checkpoints/${_.checkpointType}`].includes(pathname),
						children: [],
					};
				}),
			};
		} else {
			null;
		}
	});
	return checkpointMenu || [];
}
function categoriesCheckpointItems(data) {
	// Define an empty object to store the categorized data
	const categorizedData = {};

	// Iterate through the array and group objects by checkpointType
	data.forEach((item) => {
		const { checkpointType } = item;
		if (!categorizedData[checkpointType]) {
			// If the category doesn't exist, create an array for it
			categorizedData[checkpointType] = [item];
		} else {
			// If the category already exists, push the item to the existing array
			categorizedData[checkpointType].push(item);
		}
	});

	return categorizedData;
}
function useDynamicModuleItems() {
	const { pathname } = useLocation();
	const { userDetail } = useUserDetail();
	const { userPermissions } = useUserPermissions();
	const { dynamicModules } = useDynamicModules({});
	return (
		dynamicModules.map((_) => {
			const canGet = userDetail?.userType == "superAdmin" || !!userPermissions[`module-get${_.moduleName.replaceAll(" ", "")}`]?.value;
			return {
				title: _.moduleName,
				Icon: BallotIcon,
				link: `/module/${_.moduleName.replaceAll(" ", "")}`,
				state: { data: _ },
				permission: canGet,
				current: [`/module/${_.moduleName}`].includes(pathname),
				children: [],
				class: `dynamic-module-${_.moduleName}`,
				description: `You can manage ${_.moduleName} here.`,
			};
		}) || []
	);
}

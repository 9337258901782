import { useMemo } from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as CryptoJS from "crypto-js";
import { toast } from "react-toastify";

// export const selectInit = (callback,elem='') => {
//     window.jQuery((elem?elem:".select2-single")+":not(.bound)").addClass('bound').select2({
//       maximumSelectionSize: 6,
//       containerCssClass: ":all:",
//       minimumResultsForSearch: 10
//     }).on('change', (evt)=>{
// 		    callback((evt.target.id,evt.target.value))

// 	})

// }
// encrpt and decrypt

const SECRET_KEY = "kjhloi987klo93784950olkjhaqw239o";
export function encryptUsingAES256(request) {
	let key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
	let iv = CryptoJS.enc.Utf8.parse(SECRET_KEY);
	/*-- Encryption --*/
	let enc = CryptoJS.AES.encrypt(JSON.stringify(request), key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	}).toString();
	// console.log('encccccccccccccccccccccccccccccccc',enc)
	return enc;
}

export function decryptUsingAES256(encrypted) {
	// console.log("dencryption start...>>>  ", encrypted);
	let key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
	let iv = CryptoJS.enc.Utf8.parse(SECRET_KEY.substring(0, 16));

	let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	}).toString(CryptoJS.enc.Utf8);
	// console.log({decrypted})
	return decrypted;
}
export const debounce = (callback, wait) => {
	let timeoutId = null;
	return function (...args) {
		const context = this;
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			callback.apply(context, args);
		}, wait);
	};
};

// export const debounce = (callback, wait) => {
// 	let timeoutId = null;
// 	return (...args) => {
// 		window.clearTimeout(timeoutId);
// 		timeoutId = window.setTimeout(() => {
// 			callback.apply(null, args);
// 		}, wait);
// 	};
// };

export const base64Convert = (file) => {
	return new Promise((resolve, reject) => {
		if (!file) {
			resolve("");
		}
		var fr = new FileReader();
		fr.onload = (e) => {
			resolve(fr.result);
		};
		fr.onerror = (error) => {
			reject("Error: " + error);
		};
		fr.readAsDataURL(file);
	});
};

export const base64MimeType = (encoded) => {
	var result = null;
	if (typeof encoded !== "string") {
		return result;
	}
	try {
		var mime = encoded.split(";base64")[0].split(":")[1].split("/");
		if (mime && mime.length) {
			if (mime[0] === "image") {
				result = mime[0];
			} else {
				result = mime[1];
			}
		}
	} catch (e) {
		console.error(e.message);
	}
	return result;
};

// export const get_pdf_thumbnail = (base64) => {
//   return new Promise((resolve)=>{
//     let fileReader = new FileReader();
//     fileReader.onload = function(ev) {
//       window.pdfjsLib.getDocument(fileReader.result).promise.then(function (doc) {
//           // var pages = []; while (pages.length < doc.numPages) pages.push(pages.length + 1);
//           return doc.getPage(1).then(makeThumb)
//           .then(function (canvas) {
//             resolve(canvas.toDataURL('image/jpeg'))
//           })
//           .catch((err)=>{
//             console.log("Error",err)
//             resolve()
//           })
//           // return Promise.all(pages.map(function (num) {
//           // }))
//       }, function(error){
//           console.log(error);
//           resolve()
//       });
//     };
//     fileReader.readAsArrayBuffer(base64);
//   })
// }
const fileToBase64 = (file) => {
	if (!file) return;
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		// fileReader.readAsArrayBuffer(file);
		fileReader.onload = () => {
			resolve(fileReader.result);
		};
		fileReader.onerror = (error) => {
			reject(error);
		};
	});
};
export const make_donut_chart = (id, options) => {
	var canvas = document.getElementById(id);
	var ctx = canvas.getContext("2d");
	var total_value = 0;
	var color_index = 0;
	var colors = [];
	for (var categ of options.data) {
		colors.push(categ.color);
		total_value += categ.value;
	}
	ctx.clearRect(0, 0, canvas.width, canvas.height);
	if (total_value === 0) {
		drawPieSlice(ctx, canvas.width / 2, canvas.height / 2, Math.min(canvas.width / 2, canvas.height / 2), 0, 6.28319, "#eaeaea", "");
	} else {
		var start_angle = 0;
		for (categ of options.data) {
			var slice_angle = (2 * Math.PI * categ.value) / total_value;
			drawPieSlice(
				ctx,
				canvas.width / 2,
				canvas.height / 2,
				Math.min(canvas.width / 2, canvas.height / 2),
				start_angle,
				start_angle + slice_angle,
				colors[color_index % colors.length],
				categ["label"]
			);
			start_angle += slice_angle;
			color_index++;
		}
		//drawing a white circle over the chart
		//to create the doughnut chart
		if (options.doughnutHoleSize) {
			drawPieSlice(ctx, canvas.width / 2, canvas.height / 2, options.doughnutHoleSize * Math.min(canvas.width / 2, canvas.height / 2), 0, 2 * Math.PI, "#fff", "Total-" + total_value);
		}
		start_angle = 0;
		for (categ of options.data) {
			slice_angle = (2 * Math.PI * categ.value) / total_value;
			var pieRadius = Math.min(canvas.width / 2, canvas.height / 2);
			var labelX = canvas.width / 2 + (pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
			var labelY = canvas.height / 2 + (pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);

			if (options.doughnutHoleSize) {
				var offset = (pieRadius * options.doughnutHoleSize) / 2;
				labelX = canvas.width / 2 + (offset + pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
				labelY = canvas.height / 2 + (offset + pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);
			}

			// var labelText = Math.round(100 * categ.value / total_value);
			ctx.fillStyle = "white";
			ctx.font = "bold 12px Arial";
			if (categ.label && categ.label != "0") {
				ctx.fillText(categ.label, labelX, labelY);
			}
			start_angle += slice_angle;
		}
	}
};

export const make_pie_chart = (id, arr) => {
	var canvas = document.getElementById(id);
	var elemLeft = canvas.offsetLeft;
	var elemTop = canvas.offsetTop;
	var elements = [];
	var ctx = canvas.getContext("2d");
	var lastend = 0;
	var data = [];
	var myTotal = 0;
	var myColor = [];
	var labels = [];
	arr.map((el) => {
		data.push(el.data);
		myColor.push(el.color);
		labels.push(el.label);
	});

	for (var e = 0; e < data.length; e++) {
		myTotal += data[e];
	}

	// make the chart 10 px smaller to fit on canvas
	var off = 10;
	var w = (canvas.width - off) / 2;
	var h = (canvas.height - off) / 2;
	for (var i = 0; i < data.length; i++) {
		ctx.fillStyle = myColor[i];
		ctx.strokeStyle = "white";
		ctx.lineWidth = 2;
		ctx.beginPath();
		ctx.moveTo(w, h);
		var len = (data[i] / myTotal) * 2 * Math.PI;
		var r = h - off / 2;
		ctx.arc(w, h, r, lastend, lastend + len, false);
		ctx.lineTo(w, h);
		ctx.fill();
		ctx.stroke();
		ctx.fillStyle = "white";
		ctx.font = "16px Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		var mid = lastend + len / 2;
		ctx.fillText(labels[i], w + Math.cos(mid) * (r / 2), h - 10 + Math.sin(mid) * (r / 2));
		ctx.font = "12px Arial";
		let percent = Math.floor((data[i] / myTotal) * 100 + 0.5);
		ctx.fillText("(" + percent + "%)", w + Math.cos(mid) * (r / 2), h + 10 + Math.sin(mid) * (r / 2));
		elements.push({
			start: lastend,
			end: Math.PI * 2 * (data[i] / myTotal),
			text: `${labels[i]}:${data[i]}`,
			w,
			h,
			r,
		});
		lastend += Math.PI * 2 * (data[i] / myTotal);
	}
	// try{
	//   canvas.addEventListener('click', function(event) {
	//     console.log("hello")
	//     // var x = event.pageX - elemLeft,
	//     //     y = event.pageY - elemTop;
	//     // console.log(x, y);
	//     // console.log(elements)
	//     var mouse = oMousePos(canvas, event)
	//     try{
	//       elements.forEach(function(element) {
	//         // drawElement(element, ctx);
	//         let circle = new Path2D();
	//         circle.arc(element.w , element.h, element.r, element.start,element.end,false);
	//         let context = canvas.getContext("2d");
	//         if(context.isPointInPath(circle,mouse.x, mouse.y)){console.log(element.text)}else{console.log("not in path")}
	//       });

	//     }
	//     catch(e){
	//       console.log(e)
	//     }

	//   }, false);

	// }
	// catch(e){
	//   console.log(e)
	// }
};

export const formatLineChartData = (values, chartHeight) => {
	const widgetSize = chartHeight;
	const pointSize = 16;

	const base = (widgetSize - pointSize / 2) / values.length;

	let sortedValues = sortValues([...values]);

	const topMostPoint = sortedValues[0].value;
	let leftOffset = pointSize; //padding for left axis labels
	let nextPoint = 0;
	let rise = 0;
	let cssValues = [];

	for (var i = 0, len = values.length - 1; i < len; i++) {
		var currentValue = {
			left: 0,
			bottom: 0,
			hypotenuse: 0,
			angle: 0,
			value: 0,
		};

		currentValue.value = values[i].value;
		currentValue.name = values[i].name;
		currentValue.left = leftOffset;
		leftOffset += base;
		currentValue.bottom = (widgetSize - pointSize) * (currentValue.value / topMostPoint);
		nextPoint = (widgetSize - pointSize) * (values[i + 1].value / topMostPoint);

		rise = currentValue.bottom - nextPoint;
		currentValue.hypotenuse = Math.sqrt(base * base + rise * rise);
		currentValue.angle = radiansToDegrees(Math.asin(rise / currentValue.hypotenuse));

		cssValues.push(currentValue);
	}

	var lastPoint = {
		left: leftOffset,
		bottom: (widgetSize - pointSize) * (values[values.length - 1].value / topMostPoint),
		hypotenuse: 0,
		angle: 0,
		value: values[values.length - 1].value,
		name: values[values.length - 1].name,
	};

	cssValues.push(lastPoint);

	return cssValues;
};

const sortValues = (values) => values.sort((a, b) => b.value - a.value);

const radiansToDegrees = (rads) => rads * (180 / Math.PI);

// const sum = (total, value) => total + value.value

function drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color, label = "") {
	ctx.fillStyle = color;
	ctx.beginPath();
	ctx.moveTo(centerX, centerY);
	ctx.arc(centerX, centerY, radius, startAngle, endAngle);
	ctx.closePath();
	ctx.fill();
}

function makeThumb(page) {
	// console.log(page.view)
	// draw page to fit into 96x96 canvas
	var vp = page.getViewport({ scale: 1 });
	var canvas = document.createElement("canvas");
	canvas.width = 612;
	canvas.height = 792;
	var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
	return page
		.render({
			canvasContext: canvas.getContext("2d"),
			viewport: { scale: scale },
		})
		.promise.then(function () {
			return canvas;
		});
}

export const DOTS = "...";

const range = (start, end) => {
	let length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }) => {
	const paginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize);

		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;

		/*
	  If the number of pages is less than the page numbers we want to show in our
	  paginationComponent, we return the range [1..totalPageCount]
	*/
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		/*
	  We do not want to show dots if there is only one position left 
	  after/before the left/right page count as that would lead to a change if our Pagination
	  component size which we do not want
	*/
		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			let leftItemCount = 3 + 2 * siblingCount;
			let leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			let rightItemCount = 3 + 2 * siblingCount;
			let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			let middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [totalCount, pageSize, siblingCount, currentPage]);

	return paginationRange || [];
};

export const colourStyles = {
	menuList: (styles) => ({
		...styles,
		background: "white",
		width: "100%",
	}),
	control: (styles, state) => {
		return {
			...styles,
			backgroundColor: "transparent",

			// This line disable the blue border
			// boxShadow: 'none',
			boxShadow: state.isFocused ? "0 0 3px #grey" : state.isFocused ? "0 0 3px #ff7200" : "none",
			cursor: "pointer",
			":focus": {
				...styles[":focus"],
				//   borderColor: "var(--clr--accent)",
				// boxShadow: "0 0 3px var(--clr--accent)",
			},
			":active": {
				...styles[":active"],
				//   borderColor: "var(--clr--accent)",
				// boxShadow: "0 0 3px var(--clr--accent)",
			},

			":hover": {
				...styles[":hover"],
				// borderColor: "var(--clr--accent)",
				// boxShadow: "0 0 3px var(--clr--accent)",
			},
			height: 37,
			minHeight: 37,
			border: "1px solid #DCDCDC",
		};
	},
	option: (styles, { isDisabled, isFocused, isSelected }) => ({
		...styles,
		background: isFocused ? "#ff7200" : isSelected ? "#fff" : undefined,
		color: isFocused ? "#fff" : "black",
		":active": {
			...styles[":active"],
			backgroundColor: !isDisabled ? (isSelected ? "var(--clr--accent)" : undefined) : undefined,
		},
		cursor: isDisabled ? "not-allowed" : "default",
		":hover": {
			...styles[":hover"],
			color: !isDisabled ? "white" : "black",
			backgroundColor: !isDisabled ? "#ff7200" : "gray",
		},
		zIndex: 1,
	}),
	menu: (base) => ({
		...base, // override border radius to match the box
		// borderRadius: 0,
		// kill the gap
		// marginTop: 0,
		zIndex: 999,
	}),
	valueContainer: (provided, state) => ({
		...provided,
		height: "32px",
		margin: "-4px 0 0 0",
		outline: "none",
		fontSize: "14px",
		boxShadow: "none",
	}),

	input: (provided, state) => ({
		...provided,
		margin: "-11px 0 0",
		outline: "none",
		boxShadow: "none",
	}),
	indicatorSeparator: (state) => ({
		display: "none",
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		height: "37px",
		outline: "none",
	}),
};
export const capitalizeSentence = (sentence) => {
	const words = sentence.toLowerCase().split(" ");
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});
	return capitalizedWords.join(" ");
};
export const capitalizeCamelCase = (word) => {
	if (!word) return "";

	let letterArr = [];
	for (let i = 0; i < word.length; i++) {
		if (i != 0 && /[A-Z]/.test(word[i]) && /[a-z]/.test(word[i - 1])) {
			letterArr.push(" ");
		}
		letterArr.push(i == 0 ? word[i].toUpperCase() : word[i]);
	}
	return letterArr.join("");
};
export const removeSpecialChars = (word) => {
	if (!word) return "";
	const specialChars = [".", ",", "-", "_"];
	specialChars.forEach((c) => (word = word.split(c).join("")));
	return word;
};
export const parseUnderscore = (word) => {
	if (!word) return "";
	return word.split("_").join(" ");
};
let token = localStorage.getItem("secretkey");
export const userDetail = token ? jwtDecode(token) : {};

export const getShortText = (text, limit) => {
	// console.log(text.length, limit)
	// //(text.length > limit ? text.substring(0, limit) + "..." : text);
	return text.length > limit ? text.substring(0, limit) + "..." : text;
};
export function convertToCamelCase(str = "") {
	return str
		.split(" ")
		.map((c, i) => {
			if (i == 0) {
				return c[0].toLowerCase() + c.slice(1);
			}
			return c[0].toUpperCase() + c.slice(1);
		})
		.join("");
}
//  {data?.createdAt && moment(data?.createdAt).format("DD MMM YYYY hh:mm A")}

export const formatDate = (date, format = "DD MMM YYYY hh:mm A") => {
	if (date == "-") {
		return "-";
	}
	return date ? moment(date).format(format) : "";
};

export const redirectToTMS = (action) => {
	const lynkitio_server = import.meta.env.VITE_LYNKIT_IO_REDIRECT_URL; // #"https://beta.lynkit.io/login";
	// const lynkitio_server = "http://192.168.2.209:4302";
	const auth = "LYNKID";
	const timestamp = new Date().getTime();

	const url = new URL(lynkitio_server);
	url.searchParams.append("action", action);
	url.searchParams.append("auth", auth);
	url.searchParams.append("timestamp", timestamp);

	// console.log(url.toString())
	window.open(url.toString(), "_blank");
	const msgToSend = {
		phrase: localStorage.getItem("secretkey"),
	};
	window.addEventListener(
		"message",
		(evt) => {
			if (lynkitio_server.includes(evt.origin) && evt.data?.perkey == "sendphrase") {
				// console.log("send", msgToSend);
				evt.source.postMessage(msgToSend, evt.origin);
			}
		},
		false
	);
};

function getProjectBasedHeaders(domain) {
	let res = {};
	if (domain.includes("lynkit.io")) {
		res = {
			access_type: "service",
			project: localStorage.getItem("projectId"),
		};
	}

	return res;
}
var lynkid_domain = import.meta.env.VITE_BASE_URL || "https://web.lynkid.io/api/";
// var lynkid_domain = "http://localhost:8980/api/";

function generateAuthString(authInfo) {
	let authString = "";
	const { auth, authMethod, authParams } = authInfo || {};
	if (auth) {
		switch (authMethod) {
			case "basic": {
				let tokenString = `${authParams.username}:${authParams.password}`;
				let encodedAuthParams = window.btoa(tokenString);
				authString = `Basic ${encodedAuthParams}`;
				break;
			}
			case "bearer": {
				authString = `${authParams?.token}`;
				break;
			}
			case "inherit": {
				authString = localStorage.getItem("secretkey");
				break;
			}
		}
	} else {
		// for backwards compatibility
		authString = localStorage.getItem("secretkey");
	}
	// import.meta.env.MODE === "development" && console.log("[ DEV ] Generated Auth String --> ", authString, authInfo);
	return authString;
}

export async function customAPI(body, apiPath, method, domain = lynkid_domain, authInfo) {
	if (domain == "") {
		domain = lynkid_domain;
	}
	if (method == "get" || method == "get_URI") {
		return;
	} else {
		let authString = generateAuthString(authInfo);
		const request = new Request(`${domain}${apiPath}`, {
			method,
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: authString,
				project: localStorage.getItem("project") || "LYNKID",
				...getProjectBasedHeaders(domain),
			},
		});
		return fetch(request)
			.then((res) => res.json())
			.then((res) => {
				if (res.statusCode == 501 || res.message == "Session Expired. Please login again.") expiredTokenRedirect();
				else return res;
			});
	}
}

export function expiredTokenRedirect() {
	window.localStorage.removeItem("secretkey");
	window.localStorage.removeItem("favicon");
	window.localStorage.removeItem("title");
	window.localStorage.removeItem("primaryColor");
	window.localStorage.removeItem("selectedTheme");
	window.localStorage.removeItem("logo");
	window.localStorage.removeItem("project");
	window.localStorage.setItem("isExpired", true);
	window.location.replace("/login");
}

export function findDeepValue(obj, keys) {
	try {
		const [head, ...tail] = keys;
		if (!typeof obj == "object") return "-";
		if (Array.isArray(obj) && obj.every((ele) => ele.hasOwnProperty(head))) {
			let s = obj.reduce((p, c) => `${p} ${c[head]},`, "");
			s = s.slice(1, s.length - 1);
			return s;
		}
		if (obj[head] == undefined) return "-";
		// console.log(keys, head, obj, obj[head]);
		if (keys.length === 1) {
			if (typeof obj[head] === "boolean") {
				return obj[head] + "";
			}
			return obj[head];
		} else return findDeepValue(obj[head], tail);
	} catch (err) {
		console.error(err);
		return "-";
	}
}

export function fakeFileInput(cb, allowedTypes = ".xlsx, .xls, .csv") {
	const fakeInput = document.createElement("input");
	fakeInput.type = "file";
	fakeInput.accept = allowedTypes;
	fakeInput.onchange = function (e) {
		const file = e.target.files[0];
		let fileType = file.name.split(".").pop();
		if (
			!allowedTypes
				.split(",")
				.map((_) => _.trim().split(".").join(""))
				.includes(fileType)
		) {
			toastMessage(false, `Invalid file type: ${fileType}.`);
			return;
		}
		const formData = new FormData();
		formData.append("file", file);
		cb(formData);
	};
	fakeInput.click();
}
export function toastMessage(isSuccess, message) {
	isSuccess ? toast.success(message) : toast.error(message);
}
const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
const PDF_TYPE = "application/pdf";
const PDF_EXTENSION = ".pdf";

export function saveAsExcel(buffer, fileName) {
	const data = new Blob([buffer], { type: EXCEL_TYPE });
	FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
}

export function saveAsPDF(buffer, fileName) {
	let data = new Blob([buffer], { type: PDF_TYPE });
	FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + PDF_EXTENSION);
}

// apply theme //

// set favicon
export const setFavicon = (href) => {
	const head = document.head || document.getElementsByTagName("head")[0];
	const existingFavicon = document.getElementById("dynamic-favicon");

	// Remove existing favicon if it exists
	if (existingFavicon) {
		head.removeChild(existingFavicon);
	}

	// Create a new link element for the favicon
	const link = document.createElement("link");
	link.type = "image/x-icon";
	link.rel = "icon";
	link.href = href;
	link.id = "dynamic-favicon";

	// Append the new link element to the head
	head.appendChild(link);
};

// set title

export const setTitle = (title) => {
	document.title = title;
};

//convert text into speech

export function convertTextToSpeech(text) {
	// console.log('call for speech', text)
	// Create speech synthesis object
	var speechSynthesis = window.speechSynthesis;

	// Create a new SpeechSynthesisUtterance object
	var utterance = new SpeechSynthesisUtterance(text);

	// Speak the text
	speechSynthesis.speak(utterance);

	// speechSynthesis.cancel();
}

/**
 *
 * input : {a: 1, b: 2, { c: 3 ,{ d : 4 }}}
 * output: {a: 1, b: 2, c: 3, d: 4}
 */
export function flattenObj(res, obj = {}) {
	for (let key in obj) {
		if (Array.isArray(obj[key])) {
			if (obj[key].every((i) => typeof i == "string")) {
				res[key] = obj[key].join(",");
			} else {
				const result = obj[key].map((item) => {
					const newKey = Object.keys(item).find((ele) => ele.includes(key));
					return item[newKey];
				});
				res[key] = result.join(",");
			}
		} else if (typeof obj[key] == "object") {
			flattenObj(res, obj[key]);
		} else {
			res[key] = obj[key];
		}
	}
}
export function compareObj(obj1, obj2) {
	return JSON.stringify(obj1) == JSON.stringify(obj2);
}

export function removeEmptyKeysFromObj(obj = {}) {
	let _obj = { ...obj };
	for (let key in _obj) {
		if (_obj[key] == "") {
			delete _obj[key];
		}
	}
	return _obj;
}

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetTripcheckpointMutation, useVehicleDetailsMutation } from "../../api/vmsSlice";
import { useCheckpoints } from "../../services/hooks";
import { toastMessage } from "../../services/functions";
import GoogleMapPreview from "../utils/GoogleMap";


const defaultCenter = {
  lat: 28.46701,
  lng: 77.5853436,
};

export default function MapView() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trip_Id = queryParams.get('data');
  // console.log("location", dataValue);

  const { checkpoints : allCheckpointData, getCheckpoints } = useCheckpoints();
  const [getTripcheckpointTrigger] = useGetTripcheckpointMutation();
  const [vehicleDetailTrigger] = useVehicleDetailsMutation();

  const [vehicledata, setVehicleData] = useState({});
	const [allCheckpointList, setAllCheckpointList] = useState([]);
  const [tripCheckpoints, setTripCheckpoints] = useState([]);

  // function flattenObj(res, obj = {}) {
	// 	for (let key in obj) {
	// 		if (Array.isArray(obj[key])) {
	// 			if (obj[key].every((i) => typeof i == "string")) {
	// 				res[key] = obj[key].join(",");
	// 			} else if (obj[key].every((i) => typeof i === "object")) {
	// 				if (obj[key].every((subObj) => typeof subObj[key + "Name"] === "string")) {
	// 					// static hack for camelcase [key]Name array of objects
	// 					res[key] = obj[key].reduce((p, c) => p + c[key + "Name"] + ", ", "").replace(/,\s*$/, "");
	// 				}
	// 			}
	// 		} else if (typeof obj[key] == "object") {
	// 			flattenObj(res, obj[key]);
	// 		} else {
	// 			res[key] = obj[key];
	// 		}
	// 	}
	// }

  const generateDetail = (data, checkpointList) => {
    // console.log("data", data);
    // console.log("workflow-checkpointList", checkpointList);
		if (!data) return [];
    let lastVisitiedindex = null
    let tempcheckpoint = [];
    checkpointList?.forEach((ele, index) => {
      const { order, checkpoints = [], optional, visited } = ele;
      // console.log('checkpoints===>', index, checkpoints)
      if(visited) {
        lastVisitiedindex = index;
      }
      const currentStatus = visited ? data.statusHistory.find((ele, index) => ele.checkpointId === checkpoints[0]._id) : null;
      const previousStatus = checkpointList[index - 1] || null;
      const currentCheckpoint = allCheckpointData?.find((ele, index) => ele._id === checkpoints[0]._id) || {};
      if (currentStatus?.checkpointId) {
        // let res = data?.data?.map((_, i) => {
        //   let result = {};
        //   if (_.checkpointId === currentStatus?.checkpointId) {
        //     flattenObj(result, _.formData);
        //     delete result._id;
        //   }
        //   return result;
        // });
        // console.log("res", res);
        tempcheckpoint.push({
          id: index + 1,
          name: currentCheckpoint.displayName || 'unNamed',
          position: {
            lat: currentCheckpoint.latitude || 0,
            lng: currentCheckpoint.longitude || 0,
          },
          details: {
            vehicleNumber: data.vehicleNumber || '-',
            inTime: currentStatus.inTime || "-",
            outTime: currentStatus.outTime || "-",
          },
          visited: visited || false,
        });
      } else {
        if (previousStatus?.visited && checkpoints.length > 1) {
          checkpoints.forEach((el, i) => {
            const currentCheckpoint = allCheckpointData.find((ele, index) => ele._id === el._id);
            if(currentCheckpoint !== undefined) {
              tempcheckpoint.push({
                id: index + 1,
                name: optional ? `${currentCheckpoint.displayName}(optional)` : currentCheckpoint.displayName,
                position: {
                  lat: currentCheckpoint.latitude,
                  lng: currentCheckpoint.longitude,
                },
                details: {
                  vehicleNumber: '-',
                  inTime:  "-",
                  outTime: "-",
                },
                visited: visited || false,
              });
            }
          });
        } else {
          if(currentCheckpoint !== undefined) {
            tempcheckpoint.push({
              id: index + 1,
              name: optional ? `${currentCheckpoint.displayName}(optional)` : currentCheckpoint.displayName,
              position: {
                lat: currentCheckpoint.latitude,
                lng: currentCheckpoint.longitude,
              },
              details: {
                vehicleNumber: '-',
                inTime:  "-",
                outTime: "-",
              },
              visited: visited || false,
            });
          }
        }
      }
    });
    if (lastVisitiedindex !== null) {
      tempcheckpoint.splice(lastVisitiedindex, 0, { ...tempcheckpoint[lastVisitiedindex], lastVisited: true });
    }
    console.log("tempcheckpoint", tempcheckpoint);
    setTripCheckpoints(tempcheckpoint);
	};


  useEffect(()=> {
    if (trip_Id) {
      getTripcheckpointTrigger({ type: "all", tripId: trip_Id })
			.unwrap()
			.then((res) => {
				if (res?.error) {
					toastMessage(false, res.message);
				} else {
					setAllCheckpointList(res?.data || []);
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
      vehicleDetailTrigger({ vehicleEntryId: trip_Id })
        .unwrap()
        .then((res) => {
          setVehicleData(res?.data || {});
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [trip_Id])

  useEffect(()=> {
    if(allCheckpointList.length > 0 && vehicledata.vehicleId) {
      generateDetail(vehicledata, allCheckpointList) 
    }
  },[vehicledata, allCheckpointList])
  
  return <GoogleMapPreview markerPosition={defaultCenter} tripCheckpoints={tripCheckpoints} />;

}
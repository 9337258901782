import { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useAssetTagSocket, useAssetTags, useCheckpointData, useCheckpoints, useSocketCheckpoint, useTags, useTagsForScan, useTemplateDetail, useUserPermissions } from "../../services/hooks";
import "./Checkpoint.css";
import DynamicFormRender from "../Dynamic/DynamicForm";
import { Button, DetailCard, Dialog, Dropdown, GenericTable, Loader, generateDetailRows, tCell, toastMessage } from "../utils/index";
import theme, { buttonClasses, textClasses } from "../utils/theme";
import { useSaveCheckoutFormMutation, useSaveCheckpointFormMutation, useLazyGetCheckpointEntryDetailQuery } from "../../api/vmsSlice";
import { Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { capitalizeCamelCase, capitalizeSentence, findDeepValue, flattenObj, formatDate, parseUnderscore, removeSpecialChars } from "../../services/functions";
import { CloseIcon, EyeIcon, FilterListIcon, QrCodeIcon } from "../../services/icons";
import Genericfilter from "../utils/Genericfilter";
import Genericstats from "../utils/Genericstats";
import { debounce } from "../../services/functions";
import { useAddAssetTagMutation, useUpdateAssetTagMutation } from "../../api/deviceSlice";
import PrintLabel from "./PrintLabel";
// import DynamicFormIFrame from "../Dynamic/DynamicFormIframe";

const REDIRECTION_URL = "/not-authorized";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

function generateDetail(data, tableHeader, handlePrint) {
	if (!data) return [];
	let history = [...data.statusHistory];
	let newStaticDetail;
	// console.log(" ================== DATA ================== \n ", data)
	if (history.some((event) => event.hasOwnProperty("checkInDataID") || event.hasOwnProperty("checkOutDataID"))) {
		let eventData = [...data?.data];
		let index = 0;
		newStaticDetail = [];
		while (history.length) {
			let currentEvent = history.shift();
			let { displayName, outTime, inTime, checkpointType } = currentEvent;
			let blocks = [];
			blocks.push(
				<div className="detail_row" key={inTime + displayName}>
					<div className="detail_row_title">{checkpointType == "access" ? "Approval Request Time" : checkpointType == "bay" ? "Dock Operation Start" : "In Time"}</div>
					<div className="detail_row_value">{formatDate(inTime)}</div>
				</div>
			);
			blocks.push(
				<div className="detail_row" key={outTime + index + displayName}>
					<div className="detail_row_title">{checkpointType == "access" ? "Approval Accepted Time" : checkpointType == "bay" ? "Dock Operation End" : "Out Time"}</div>
					<div className="detail_row_value">{formatDate(outTime)}</div>
				</div>
			);
			while (eventData.length && (eventData.type == "In / Out" || eventData[0]._id == currentEvent?.checkInDataID || eventData[0]._id == currentEvent?.checkOutDataID)) {
				let currentData = eventData.shift();
				if (currentData.type == "In / Out") {
					continue;
				}
				let processedData = {};
				let type = currentData.type;
				flattenObj(processedData, currentData.formData);
				let rows = [];
				for (let key in processedData) {
					if (processedData[key]) {
						rows.push(
							<div className="detail_row" key={key}>
								<div className="detail_row_title">{capitalizeSentence(capitalizeCamelCase(parseUnderscore(key)))}</div>
								<div className="detail_row_value">{processedData[key]}</div>
							</div>
						);
					}
				}
				let block = (
					<div className="statusHistory-detail-content" style={{ margin: "0", minHeight: "50px" }}>
						<div style={{ fontSize: "0.9rem", fontWeight: "bold", textAlign: "center", color: "var(--text-color)" }}>{capitalizeSentence(type)}</div>
						<div style={{ padding: "0", borderRadius: "12px", color: "var(--text-color)" }}>{rows}</div>
					</div>
				);
				if (rows && rows.length) {
					blocks.push(block);
				}
			}
			let view = (
				<div key={index++} className="statusHistory-detail-content" style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
					<div style={{ fontWeight: "bold", textAlign: "center", color: "var(--text-color)" }}>{displayName}</div>
					{blocks}
				</div>
			);
			newStaticDetail.push(view);
		}
	} else {
		newStaticDetail = data.statusHistory?.map(({ displayName, inTime, outTime, checkpointId, checkpointType, _id }, i) => {
			let type;
			let res = data?.data?.map((_, i) => {
				let result = {};
				if (_._id === _id) {
					//previous cond==> _.checkpointId === checkpointId
					flattenObj(result, _.formData);
					type = _.type;
					delete result._id;
				}
				return result;
			});
			// console.log('detail res===>', res)
			let rows = [];
			for (let obj of res) {
				for (let key in obj) {
					if (obj[key]) {
						rows.push(
							<div className="detail_row" key={key}>
								<div className="detail_row_title">{capitalizeSentence(capitalizeCamelCase(parseUnderscore(key)))}</div>
								<div className="detail_row_value">{obj[key]}</div>
							</div>
						);
					}
				}
			}
			rows.push(
				<div className="detail_row" key={inTime + displayName}>
					<div className="detail_row_title">{checkpointType == "access" ? "Approval Request Time" : checkpointType == "bay" ? "Dock Operation Start" : "In Time"}</div>
					<div className="detail_row_value">{formatDate(inTime)}</div>
				</div>
			);
			rows.push(
				<div className="detail_row" key={outTime + i + displayName}>
					<div className="detail_row_title">{checkpointType == "access" ? "Approval Accepted Time" : checkpointType == "bay" ? "Dock Operation End" : "Out Time"}</div>
					<div className="detail_row_value">{formatDate(outTime)}</div>
				</div>
			);
			const view = (
				<div key={i} className="statusHistory-detail-content">
					<div style={{ fontWeight: "bold", textAlign: "center", color: "var(--text-color)" }}>{displayName}</div>
					<div style={{ padding: "0.5rem", borderRadius: "12px", color: "var(--text-color)" }}>{rows}</div>
				</div>
			);
			return view;
		});
	}
	const details = tableHeader.map((_) => ({ label: _.label, value: _.type == "date" ? formatDate(findDeepValue(data, _.name.split("."))) : findDeepValue(data, _.name.split(".")) }));
	return (
		<>
			<div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginBottom: "1rem" }}>
				<Button onClick={handlePrint} style={{ ...buttonClasses.lynkitOrangeEmpty }}>
					<QrCodeIcon /> Print Label
				</Button>
			</div>
			{generateDetailRows(details)}
			{newStaticDetail}
		</>
	);
}

const currCheckpointPerm = (currentCheckpoint, userPermissions) => {
	if (!currentCheckpoint) return true;
	if (!Object.keys(userPermissions).length) return true;
	const permission = {};
	Object.keys(userPermissions).forEach((ele, index) => {
		if (ele.includes(currentCheckpoint)) {
			const match = ele.match(/[^-]+$/);
			const result = match ? (match[0] == currentCheckpoint ? "currentPage" : match[0]) : "";
			permission[result] = userPermissions[ele];
		}
	});

	return permission;
};

/**
 * This component is used to render different data based on the Current Checkpoint
 */
const Checkpoint = () => {
	const { userPermissions, isFetched } = useUserPermissions();
	const location = useLocation();

	const { checkpoints, isLoading, isCheckpointFetched } = useCheckpoints();

	// grab checkpoint type and number from the url params
	const { type, number } = useParams();

	// real time data from socket; we merge it with the other data when rendering
	const { checkpointEvents } = useSocketCheckpoint(number || "");

	// check if the type and number from the params are valid
	const isInValidCheckpoint = !checkpoints.find((_) => _.checkpointType == type && _.checkpointNumber == number);

	// extract information of current checkpoint
	const currentCheckpoint = checkpoints.find((_) => (number ? _.checkpointType == type && _.checkpointNumber == number : _.checkpointType == type)) || {};
	const { displayName, _id: checkpointId, listingConfig, checkoutConfig = {}, checkpointNumber, detailApi, assetConfig = {}, sendApproval, templateId } = currentCheckpoint;
	const { checkoutOnSubmit, buttonLabel: checkoutBtnLabel, api: checkoutFormEndpoint, checkoutOnApproval, entrySuccessText = "Submitted" } = checkoutConfig;
	const { assetAdd, assetScan } = assetConfig;
	// find all permissions for the current checkpoint
	const currentChkPermission = currCheckpointPerm(checkpointId, userPermissions);

	// a ref to keep track of filters
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;

	const { trigger, checkpointData, isLoading: isCheckpointDataLoading, tableHeader, count } = useCheckpointData(filterRef.current);

	const { templateDetail, getTemplateDetail } = useTemplateDetail(null, "filterForm");
	useEffect(() => {
		if (templateId) {
			getTemplateDetail({ id: templateId, onlyFilterFields: true });
		}
	}, [templateId]);
	const primaryFilters = [];
	const secondaryFilters = [];
	for (let attr of templateDetail?.attributes || []) {
		let _attr = { ...attr, required: false };
		if (attr.isPrimaryFilter) {
			primaryFilters.push(_attr);
		}
		secondaryFilters.push(_attr);
	}
	// console.log({ primaryFilters });
	const [showFilters, setShowFilters] = useState(false);
	// sort the tabs based on order
	const tabs = [...(listingConfig || [])].sort((a, b) => a.order - b.order);
	const currentTab = tabs.find((t) => t.type == filterRef.current?.type);
	const onTabChange = (e, type) => {
		filterRef.current = { ...filterRef.current, type };
		trigger(filterRef.current);
	};

	// for the Asset Mapping and Scanning feature
	const [isAssetScan, setIsAssetScan] = useState(false);
	const [isAssetMap, setIsAssetMap] = useState(false);

	const canAssetAdd = assetAdd && currentChkPermission?.assetAdd?.value;
	const canAssetScan = assetScan && currentChkPermission?.assetScan?.value;

	const [startDate, setStartDate] = useState(null);
	const [openPrint, setOpenPrint] = useState(false);

	// a ref to keep the vehicle entry ID
	const vehicleEntryIdRef = useRef("");

	// fetch data after applying filters
	const updateFilterAndTrigger = (filter) => {
		const type = tabs && tabs[0] && tabs[0].type;
		filterRef.current = { ...filter, checkpointId, type };
		trigger(filterRef.current);
		setIsForm(false);
		setIsDetail(false);
	};
	let filterFields = [
		{
			inputType: "searchDropdown",
			id: "1",
			name: "vehicleNumber",
			type: "text",
			label: "Vehicle No./ Trip Id",
			placeholder: "Enter search value",
			options: [
				{ label: "Vehicle Number", value: "vehicleNumber" },
				{ label: "Trip ID", value: "trip_counter" },
			],
			readOnly: false,
			error: "",
			value: "",
		},
		{
			inputType: "datePicker",
			id: "3",
			name: "start",
			label: "Start Date",
			maxDATE: dayjs(),
			readOnly: false,
			error: "",
			value: "",
		},
		{
			inputType: "datePicker",
			id: "4",
			name: "end",
			label: "End Date",
			minDATE: startDate ? dayjs(startDate) : null,
			maxDATE: dayjs(),
			readOnly: false,
			error: "",
			value: "",
		},
		{
			inputType: "dropdown",
			id: "3",
			name: "trip_status",
			label: "Trip Status",
			option: [
				{ label: "Ongoing", value: "Ongoing" },
				{ label: "Terminated", value: "Terminated" },
				{ label: "Cancelled", value: "Cancelled" },
				{ label: "Completed", value: "Completed" },
			],
		},
	];

	const handlePrint = () => {
		setOpenPrint(true);
		// console.log('calling print')
	};

	useEffect(() => {
		if (!checkpointId) return;
		updateFilterAndTrigger(filterRef.current);
	}, [checkpointId]);

	useEffect(() => {
		if (location.pathname) {
			updateFilterAndTrigger(defaultFilter);
		}
	}, [location.pathname]);

	// useEffect(() => {
	// 	if (checkpointEvents.length > 0) {
	// 		if (!checkpointId) return;
	// 		updateFilterAndTrigger(filterRef.current);
	// 	}

	// }, [checkpointEvents])

	const [handleCheckout] = useSaveCheckoutFormMutation();
	const [getDetail, { data, isFetching: isFetchingDetail }] = useLazyGetCheckpointEntryDetailQuery();
	const { data: detailData, tableHeader: detailTableHeaders } = data || { tableHeader: [] };
	const detail = generateDetail(detailData, detailTableHeaders, handlePrint);

	const [isDetail, setIsDetail] = useState(null);
	const handleDetailClose = () => {
		setIsDetail(false);
	};
	const updateDataRef = useRef(null);

	const checkoutDataRef = useRef(null);

	const [isModal, setIsModal] = useState(false);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};
	const onModalToggle = () => setIsModal(!isModal);

	const [isForm, setIsForm] = useState(false);
	const onFormClose = () => {
		setIsForm(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};
	const onFormToggle = () => setIsForm(!isForm);

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		trigger(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		trigger(filterRef.current);
	};

	const handleSpeech = (event, id) => {
		event.preventDefault();
	};

	const checkCheckpointData = (checkpointEvents, checkpointData) => {
		if (!checkpointData || !checkpointData.length) {
			return [...checkpointEvents];
		}
		let rangeCheckoutEvents = new Set();
		const uniqueElements = checkpointEvents.filter((event) => {
			if (event.SOCKET_EVENT_TYPE == "range-checkout") {
				rangeCheckoutEvents.add(event._id);
			} else {
				return !checkpointData.some((item) => item._id === event._id);
			}
			return true;
		});
		checkpointData = checkpointData.filter((trip) => !rangeCheckoutEvents.has(trip._id));
		const result = [...uniqueElements, ...checkpointData];
		return result;
	};

	let actionHandlers = {
		view: async (dataEntry) => {
			const { _id } = dataEntry;

			//harcoding for vehicle entry detail.
			const payload = { vehicleEntryId: _id };
			getDetail([detailApi, payload]);
			setIsDetail(true);
		},
		submit: async (dataEntry) => {
			checkoutDataRef.current = dataEntry;
			if (checkoutOnSubmit) {
				const payload = { checkout: true, checkpointNumber, vehicleId: dataEntry.vehicleId };
				const { data } = await handleCheckout([checkoutFormEndpoint, payload]);
				const { error, message } = data || {};

				if (data && error) {
					toastMessage(false, message);
				} else {
					toastMessage(true, message);
				}
			} else {
				onModalToggle();
			}
		},
	};

	let header = ["S.No", ...(tableHeader || []).map((_) => _.label)];

	if (sendApproval) header.push("Approval Status");
	header.push("Action");

	const filteredCheckpointData = checkpointData?.length > 0 || checkpointEvents?.length > 0 ? checkCheckpointData(checkpointEvents, checkpointData) : [];

	// console.log("filtercheckpointData====>", filteredCheckpointData, checkpointEvents);

	let rows = [...filteredCheckpointData].map((data, index) => {
		let row = [tCell(index + 1)];

		for (let item of tableHeader) {
			if (item.name === "vehicleNumber") {
				row.push(tCell(findDeepValue(data, item.name.split(".")), "", "pointer", handleSpeech, data._id));
			} else {
				row.push(tCell(item.type === "date" ? formatDate(findDeepValue(data, item.name.split("."))) : findDeepValue(data, item.name.split("."))));
			}
		}

		const formattedStatus = capitalizeCamelCase(removeSpecialChars(data.approvalStatus));
		const approvalStatus = (
			<span className={`status ${!data.hasOwnProperty("isApproved") ? "Yellow" : data.isApproved ? "green" : "red"}`} title={formattedStatus}>
				{formattedStatus}
			</span>
		);

		if (sendApproval) row.push(tCell(approvalStatus));
		let handlers = { ...actionHandlers };

		const submitBtn = <Button onClick={() => handlers.submit(data)} text={checkoutBtnLabel} style={{ ...buttonClasses.lynkitOrangeFill }} />;
		let submittedText = "";
		if (filterRef.current?.type === "pending") {
			submittedText = "";
		} else {
			submittedText = tCell(<span className="status green">{entrySuccessText}</span>);
		}

		let curr_chkpntList = data.statusHistory.filter((obj) => obj.checkpointId === checkpointId);
		let curr_chkpnt = curr_chkpntList[curr_chkpntList.length - 1];
		let atCheckpoint = "NO";

		if (curr_chkpnt?.checkpointId && !checkoutOnApproval && !currentTab?.hideSubmit && ["Completed", "Ongoing"].includes(data.trip_status)) {
			// console.log("curr_chkpnt", curr_chkpnt, checkoutOnApproval, currentTab?.hideSubmit);
			// Action button only if end
			if (curr_chkpnt.inTime && !curr_chkpnt.outTime) {
				atCheckpoint = "YES";
			} else {
				atCheckpoint = "SUBMITTED";
			}
		} else {
			// No action button.
			atCheckpoint = "NO";
		}

		const action = (
			<div>
				<div>
					<EyeIcon onClick={() => handlers.view(data)} className="view-icon" />
				</div>
				<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
					{atCheckpoint === "NO" ? (
						""
					) : atCheckpoint === "YES" ? (
						<>
							{submitBtn}
							{canAssetAdd ? (
								<Button
									text={"Map Assets"}
									style={{ ...buttonClasses.lynkitOrangeFill, width: "7rem" }}
									onClick={() => {
										setIsAssetMap(true);
										vehicleEntryIdRef.current = data;
									}}
								/>
							) : null}
							{canAssetScan ? (
								<Button
									text={"Scan Assets"}
									style={{ ...buttonClasses.lynkitOrangeFill }}
									onClick={() => {
										setIsAssetScan(true);
										vehicleEntryIdRef.current = data;
									}}
								/>
							) : null}
						</>
					) : (
						submittedText
					)}
				</div>
			</div>
		);

		row.push(action);

		return row;
	});

	const handleFilterChange = (filter) => {
		if (Object.keys(filter)?.length > 0) {
			let obj = {};
			if (filter?.vehicleNumber || filter?.trip_counter || (filter?.start && filter?.end) || filter?.trip_status) {
				if (Object.keys(filter).includes("vehicleNumber") || Object.keys(filter).includes("trip_counter")) {
					obj["searchBy"] = filter?.vehicleNumber ? ["vehicleNumber"] : ["trip_counter"];
					obj["search"] = filter?.vehicleNumber || filter?.trip_counter || "";
				} else {
					obj["searchBy"] = "";
					obj["search"] = "";
				}
				if (filter?.start && filter?.end) {
					const newEndDate = dayjs(filter?.end).add(1, "day").toDate().toISOString();
					obj = { ...obj, start: filter?.start, end: newEndDate };
				}
				if (filter?.trip_status) {
					obj["trip_status"] = filter?.trip_status?.value;
				} else {
					obj["trip_status"] = "";
				}
			}
			if (Object.keys(obj).length > 0) {
				filterRef.current = { ...filterRef.current, ...obj };
				trigger(filterRef.current);
			}
		}

		if (Object.keys(filter)?.length === 0) {
			filterRef.current = { ...filterRef.current, ...{ start: "", end: "", searchBy: "", search: "" } };
			trigger(filterRef.current);
		}
	};
	const onFilterSubmit = (form) => {
		console.log(form);
	};
	const onFilterChange_dynamic = useCallback(
		debounce((name, value) => {
			console.log({ name, value });
		}, 500),
		[]
	);
	const addButton = currentChkPermission.manualEntry?.value ? <Button onClick={onFormToggle} text={isForm ? "Go Back" : "Add New"} style={{ ...buttonClasses.lynkitOrangeEmpty }} /> : null;

	if (isLoading) return <Loader size="3rem" height="100%" />;
	if (
		(isCheckpointFetched && isInValidCheckpoint) ||
		(checkpointId && isFetched && userPermissions && Object.keys(userPermissions).length && !Object.keys(userPermissions).find((key) => key.includes(checkpointId)))
	) {
		return <Navigate to={REDIRECTION_URL} replace />;
	}
	return (
		<>
			<div className="checkpoint-module">
				<Grid container spacing="1rem" className="checkpoint_panel" style={{ alignItems: "center" }}>
					<Grid item lg={11} md={10} sm={10} xs={8} className="checkpoint_heading">
						{displayName}
					</Grid>
					{currentChkPermission?.manualEntry ? (
						<Grid item lg={1} md={2} sm={2} xs={4}>
							{addButton}
						</Grid>
					) : null}
				</Grid>

				{isForm ? (
					<CheckpointForm open={isForm} onClose={onFormClose} updateData={updateDataRef.current} />
				) : (
					<>
						<div className="cust-row flex-algn-cent">
							<Genericstats checkpointID={checkpointId} filterType={filterRef.current?.type} />
						</div>
						{tabs?.length ? (
							<div className="checkpoint_tabs">
								<Tabs
									value={filterRef.current.type || tabs[0]?.type}
									onChange={onTabChange}
									sx={{
										"& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
										"& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
									}}
								>
									{tabs?.map(({ label, type }, index) => {
										return <Tab value={type} label={label} key={index} sx={{ ...textClasses.normalText, textTransform: "none", color: "var(--text-color)" }} />;
									})}
								</Tabs>
							</div>
						) : null}

						{/* <DynamicFormRender
							previewAttributes={primaryFilters}
							checkpointId={checkpointId}
							onFilterChange={onFilterChange_dynamic}
							filterOnly
							noBtn
							renderAction={(onClear) => {
								return (
									<div
										style={{
											display: "flex",
											gap: "1rem",
											alignItems: "center",
											// justifyContent: "center",
										}}
									>
										<Button onClick={onClear} text={"Clear All"} style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }} />
										<Button style={{ ...buttonClasses.lynkitOrangeFill, width: "fit-content" }} onClick={() => setShowFilters(true)}>
											<FilterListIcon /> Filter(s)
										</Button>
									</div>
								);
							}}
						/> */}

						{/* <Drawer open={showFilters} onClose={() => setShowFilters(false)}>
							<div style={{ width: "35vw" }}>
								<div style={{ position: "relative", borderBottom: "1px solid var(--text-color)", padding: "1rem", display: "flex", alignItems: "center", gap: "0.75rem" }}>
									<CloseIcon sx={{ cursor: "pointer" }} onClick={() => setShowFilters(false)} />
									<span style={{ fontSize: "1.4rem" }}>Advance Filters</span>
								</div>
								<div style={{ width: "35vw", padding: "1rem" }}>
									<DynamicFormRender
										previewAttributes={secondaryFilters}
										checkpointId={checkpointId}
										filterOnly
										btn2Text="Apply"
										onSubmit={onFilterSubmit}
										noBtn
										renderAction={(onClear, onSubmit) => {
											return (
												<div
													style={{
														display: "flex",
														gap: "1rem",
														alignItems: "center",
														justifyContent: "center",
														marginTop: "1rem",
													}}
												>
													<Button onClick={onClear} text={"Clear All"} style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }} />
													<Button onClick={onSubmit} type="submit" text={"Apply"} style={{ ...buttonClasses.lynkitOrangeFill, width: "fit-content" }} />
												</div>
											);
										}}
									/>
								</div>
							</div>
						</Drawer> */}
						<div className="cust-row flex-algn-cent">
							<Genericfilter addOnClear={true} filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
						</div>
						<div className="main_content">
							<GenericTable
								header={header}
								rows={rows}
								TableData={[...filteredCheckpointData]}
								pageCount={count}
								pageNo={page_no}
								limit={pagesize}
								onPageChange={onPageChange}
								onPageSizeChange={onPageSizeChange}
								isLoading={isCheckpointDataLoading}
								IsScroll={true}
							/>
							<DetailCard isOpen={isDetail} onClose={handleDetailClose} title={"Detail"}>
								{isFetchingDetail ? <Loader /> : detail}
							</DetailCard>
						</div>
					</>
				)}

				{openPrint ? (
					<PrintLabel
						open={openPrint}
						onClose={() => {
							setOpenPrint(false);
						}}
						checkpointId={checkpointId}
						detailData={detailData}
						detailTableHeaders={detailTableHeaders}
					/>
				) : null}
				{isModal ? <CheckpointFormPopup open={isModal} onClose={onModalClose} updateData={updateDataRef.current} checkoutData={checkoutDataRef.current} /> : null}
				{isAssetMap ? (
					<AssetAddForm
						open={isAssetMap}
						onClose={() => setIsAssetMap(false)}
						vehicleData={vehicleEntryIdRef.current}
						vehicleEntryId={vehicleEntryIdRef.current?.trip_counter}
						checkpointId={checkpointId}
					/>
				) : null}
				{isAssetScan ? (
					<AssetScanForm
						open={isAssetScan}
						onClose={() => setIsAssetScan(false)}
						vehicleData={vehicleEntryIdRef.current}
						vehicleEntryId={vehicleEntryIdRef.current?.trip_counter}
						checkpointId={checkpointId}
					/>
				) : null}
			</div>
		</>
	);
};

export default Checkpoint;

function CheckpointForm({ onClose, open, updateData }) {
	const { type, number } = useParams();
	const { checkpoints, isLoading } = useCheckpoints();
	const isInValidCheckpoint = !checkpoints.find((_) => _.checkpointType == type);
	const currentCheckpoint = checkpoints.find((_) => _.checkpointType == type && _.checkpointNumber == number) || {};

	const { templateId, api: formEndpoint, checkpointNumber, _id: checkpointId } = currentCheckpoint;

	const [handleSubmit, { isLoading: isSubmitting }] = useSaveCheckpointFormMutation();
	const handleFormSubmit = async (data) => {
		const payload = { ...data, checkpointNumber };
		const {
			data: { error, message },
		} = await handleSubmit([formEndpoint, payload]);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			handleClose();
		}
	};
	const handleClose = () => {
		onClose();
	};

	if (isLoading) return <Loader size="3rem" height="100%" />;
	if (isInValidCheckpoint) return <Navigate to={REDIRECTION_URL} replace={true} />;

	return (
		<div style={{ marginTop: "1.5rem" }}>
			{/* <DynamicFormIFrame templateId={templateId} checkpointId={checkpointId} onClose={handleClose} onSubmit={handleFormSubmit} isSubmitDisabled={isSubmitting}/> */}
			<DynamicFormRender templateId={templateId} checkpointId={checkpointId} onClose={handleClose} onSubmit={handleFormSubmit} isSubmitDisabled={isSubmitting} />
		</div>
	);
}

function CheckpointFormPopup({ onClose, open, updateData, checkoutData }) {
	const { type, number } = useParams();
	const { checkpoints, isLoading } = useCheckpoints();
	const isInValidCheckpoint = !checkpoints.find((_) => _.checkpointType == type);
	const currentCheckpoint = checkpoints.find((_) => (number ? _.checkpointType == type && _.checkpointNumber == number : _.checkpointType == type)) || {};
	const { checkpointNumber, checkoutConfig = {}, _id: checkpointId } = currentCheckpoint;

	const { templateId: checkoutTemplateId, api, submitFormTitle } = checkoutConfig;

	const [handleCheckout, { isLoading: isSubmitting }] = useSaveCheckoutFormMutation();
	const handleFormSubmit = async (formData) => {
		const payload = { ...formData, checkpointNumber };
		const {
			data: { error, message },
		} = await handleCheckout([api, payload]);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			handleClose();
		}
	};

	const handleClose = () => {
		onClose();
	};
	if (isLoading) return <Loader size="3rem" height="100%" />;
	if (isInValidCheckpoint) return <Navigate to={REDIRECTION_URL} replace />;

	return (
		<Dialog size="lg" open={true} handleClose={onClose} title={submitFormTitle} noBtn>
			<DynamicFormRender
				templateId={checkoutTemplateId}
				checkpointId={checkpointId}
				checkoutData={checkoutData}
				isSaveDraft
				onSubmit={handleFormSubmit}
				extraClass="popup"
				btnText="Save Draft"
				isSubmitDisabled={isSubmitting}
			/>
		</Dialog>
	);
}

function useScanBtn(onScanOver) {
	const ref = useRef(null);
	const [scannedText, setScannedText] = useState("");

	const [isFocus, setIsFocus] = useState(false);
	useEffect(() => {
		if (!isFocus || !ref.current) return;

		const focusListener = (e) => {
			setIsFocus(false);
		};
		const focusInListener = (e) => {
			setIsFocus(true);
		};
		ref.current.addEventListener("focusout", focusListener);
		ref.current.addEventListener("focusin", focusInListener);

		return () => {
			ref.current?.removeEventListener("focusout", focusListener);
			ref.current?.removeEventListener("focusin", focusInListener);
		};
	}, [isFocus]);

	const hiddenInput = (
		<input
			type="text"
			style={{ opacity: 0, position: "absolute" }}
			ref={ref}
			onChange={(e) => {
				setScannedText(e.target.value);
			}}
			onKeyDown={(e) => {
				if (e.key == "Enter") {
					onScanOver(scannedText);
					setScannedText("");
				}
			}}
			value={scannedText}
		/>
	);
	const scannBtnUI = (
		<Grid container spacing={3} alignItems={"center"}>
			{hiddenInput}
			<Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
				<Button
					text={"Click to Start Scanning"}
					onClick={() => {
						ref.current?.focus();
						setIsFocus(true);
					}}
					style={{ ...buttonClasses.lynkitBlackEmpty }}
				/>
			</Grid>
			<Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
				Status : {isFocus ? <span style={{ color: "green" }}>Can Scan</span> : <span style={{ color: "red" }}>Cannot Scan</span>}
			</Grid>
		</Grid>
	);

	return { scannBtnUI };
}

function AssetAddForm({ open, onClose, vehicleData, vehicleEntryId, checkpointId }) {
	const [form, setForm] = useState({
		tags: [],
	});
	const { getTags, tagOptions } = useTags({ page_no: 1, pagesize: 100, status: "Allocated", allocationType: "!vehicle" });
	let tagValues = tagOptions?.filter((tagOp) => form.tags?.find((tag) => tag.value == tagOp.value));

	const { getTagsScan, scannedTags: scannedTagsFromServer, clearData } = useTagsForScan();
	const [scannedTags, setScannedTags] = useState([]);
	useAssetTagSocket(setScannedTags);
	useEffect(() => {
		if (!scannedTagsFromServer.length) return;
		setScannedTags((o) => [...scannedTagsFromServer, ...o]);
	}, [scannedTagsFromServer]);

	const { scannBtnUI } = useScanBtn((scannedText) => {
		getTagsScan({ epc: scannedText }); // tid: scannedText
	});

	let payload = {
		checkpointId,
		vehicleEntryId,
		tags: [
			...scannedTags.map((data) => {
				return {
					displayName: data.displayName,
					epc: data.epc,
					tId: data.tId,
					assetName: data.allocationDetails?.assetName,
					assetId: data.allocationDetails?.assetId,
					type: "scanned",
				};
			}),
			...form.tags.map(({ data }) => {
				return {
					displayName: data.displayName,
					epc: data.epc,
					tId: data.tId,
					assetName: data.allocationDetails?.assetName,
					assetId: data.allocationDetails?.assetId,
					type: "selected",
				};
			}),
		],
	};
	const handleDropdownSearch = useCallback(
		debounce((value) => {
			getTags({ search: value });
		}, 500),
		[]
	);

	const handleClose = () => {
		onClose();
		setScannedTags([]);
		clearData();
	};

	const [addAssetTag] = useAddAssetTagMutation();
	const handleSubmit = async () => {
		const {
			data: { error, message },
		} = await addAssetTag(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			handleClose();
		}
	};

	const handleItemRemove = (i, op) => {
		if (op.type == "scanned") {
			setScannedTags((old) => old.filter((_, j) => j != i));
		} else if (op.type == "selected") {
			if (op.epc) {
				setForm((o) => ({ ...o, tags: o.tags.filter((_) => _.data?.epc != op.epc) }));
			} else if (op.tId) {
				setForm((o) => ({ ...o, tags: o.tags.filter((_) => _.data?.tId != op.tId) }));
			}
		}
	};
	return (
		<Dialog open={open} title={`Map Assets (Trip ID : ${vehicleData.trip_counter}, Vehicle Number : ${vehicleData.vehicleNumber})`} handleClose={handleClose} handleSubmit={handleSubmit} size="lg">
			<Grid container spacing={1} mb={2}>
				<Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
					<Dropdown
						name="tags"
						label={"Tags"}
						options={tagOptions}
						value={tagValues}
						onChange={(ops, name) => {
							setForm((o) => ({ ...o, [name]: ops }));
						}}
						multiple
						onInputChange={({ target: { value } }) => {
							handleDropdownSearch(value);
						}}
						openOnFocus
					/>
				</Grid>
			</Grid>
			{scannBtnUI}

			<AssetTable assets={payload.tags} handleRemove={handleItemRemove} />
		</Dialog>
	);
}

function AssetScanForm({ open, onClose, vehicleData, vehicleEntryId, checkpointId }) {
	const { assetTags: assetTagsServer, isLoading } = useAssetTags({ vehicleEntryId });

	const [assetTags, setAssetTags] = useState([]);
	useAssetTagSocket(setAssetTags);
	let list = [];
	if (!assetTags.length && !assetTagsServer.length) {
		list = [];
	} else if (!assetTags.length && assetTagsServer.length) {
		list = [...assetTagsServer];
	} else if (assetTags.length && !assetTagsServer.length) {
		list = [...assetTags];
	} else {
		for (let tag of assetTagsServer) {
			let scannedTag = assetTags.find((_) => _.tId == tag.tId || _.epc == tag.epc);
			if (scannedTag) {
				list.unshift(scannedTag);
			} else {
				list.unshift(tag);
			}
		}
	}

	const { scannBtnUI } = useScanBtn((scannedText) => {
		let matchedTag = assetTagsServer.find((_) => _.epc == scannedText || _.tId == scannedText);
		if (matchedTag) {
			setAssetTags((old) => {
				return [
					{
						displayName: matchedTag.displayName,
						epc: matchedTag.epc,
						tId: matchedTag.tId,
						assetName: matchedTag.assetName,
						assetId: matchedTag.assetId,
						isScan: true,
						type: "scanned",
					},
					...old,
				];
			});
		} else {
			console.error(`You have scanned an Invalid Tag : ${scannedText}`);
		}
	});
	const [updateAssetTag] = useUpdateAssetTagMutation();
	const handleSubmit = async () => {
		const payload = {
			checkpointId,
			updatedTids: assetTags.filter((_) => _.isScan).map((_) => _.tId),
			updatedEpcs: assetTags.filter((_) => _.isScan).map((_) => _.epc),
		};
		const {
			data: { error, message },
		} = await updateAssetTag(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onClose();
		}
	};
	return (
		<Dialog open={open} size="lg" title={`Scan Assets (Trip ID : ${vehicleData.trip_counter}, Vehicle Number : ${vehicleData.vehicleNumber})`} handleClose={onClose} handleSubmit={handleSubmit}>
			{isLoading ? (
				<Loader size="3rem" height="100%" />
			) : (
				<>
					{scannBtnUI}
					<AssetTable assets={list} />
				</>
			)}
		</Dialog>
	);
}

function AssetTable({ assets, handleRemove }) {
	return (
		<TableContainer component={Paper} sx={{ marginTop: "1rem", maxHeight: "50vh", overflow: "auto" }}>
			<Table aria-label="simple table">
				<TableHead sx={{ backgroundColor: "var(--table-header-bg-color)" }}>
					<TableRow>
						<TableCell>Sno</TableCell>
						<TableCell align="center">Tag Name</TableCell>
						<TableCell align="center">TID</TableCell>
						<TableCell align="center">EPC</TableCell>
						{/* <TableCell align="center">Asset Name</TableCell> */}
						{/* <TableCell align="center">Asset ID</TableCell> */}
						<TableCell align="center">Scanned</TableCell>
						{handleRemove ? <TableCell align="center">Action</TableCell> : null}
					</TableRow>
				</TableHead>
				<TableBody sx={{ backgroundColor: "var(--table-body-bg-color)", color: "var(--text-color)" }}>
					{assets?.map((op, i) => {
						return (
							<TableRow
								key={i}
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
									backgroundColor: op.type == "scanned" ? "#18A558" : "",
								}}
							>
								<TableCell align="center">{i + 1}</TableCell>
								<TableCell align="center">{op.displayName}</TableCell>
								<TableCell align="center">{op.tId || "-"}</TableCell>
								<TableCell align="center">{op.epc || "-"}</TableCell>
								{/* <TableCell align="center">{op.assetName || "-"}</TableCell> */}
								{/* <TableCell align="center">{op.assetId || "-"}</TableCell> */}
								<TableCell align="center">{op.isScan ? "Done" : "Pending"}</TableCell>
								{handleRemove ? (
									<TableCell align="center">
										<CloseIcon style={{ fill: "var(--icon-color)", cursor: "pointer" }} onClick={() => handleRemove(i, op)} />
									</TableCell>
								) : null}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

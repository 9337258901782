import { apiSlice } from "./api";

const userApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation({
			query: (body) => {
				return {
					url: "users/login",
					method: "post",
					body,
				};
			},
		}),
		forgotPassword: build.mutation({
			query: (body) => {
				return {
					url: "users/forgot-password",
					method: "post",
					body,
				};
			},
		}),
		verifyOtp: build.mutation({
			query: (body) => {
				return {
					url: "users/verify-otp",
					method: "post",
					body,
				};
			},
		}),
		updatePassword: build.mutation({
			query: (body) => {
				return {
					url: "users/update-password",
					method: "post",
					body,
				};
			},
		}),
		changePassword: build.mutation({
			query: (body) => {
				return {
					url: "users/change-password",
					method: "post",
					body,
				};
			},
		}),

		getUsers: build.mutation({
			query: (body) => {
				return {
					url: "users/get-users",
					method: "post",
					body,
				};
			},
			providesTags: ["users"],
		}),
		addUser: build.mutation({
			query: (body) => {
				return {
					url: "users/add-user",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["users"],
		}),
		updateUser: build.mutation({
			query: (body) => {
				return {
					url: "users/update-user",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["users"],
		}),
        updateUserPermission: build.mutation({
			query: (body) => {
				return {
					url: "users/update-user-permission",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["users"],
		}),
		getCurrentUserDetail: build.query({
			query: (body) => {
				return {
					url: "users/get-user-detail",
					method: "post",
					body,
				};
			},
			// providesTags: ["user-detail"],
		}),
		getUserDetail: build.mutation({
			query: (body) => {
				return {
					url: "users/get-user-detail",
					method: "post",
					body,
				};
			},
		}),
		getOrgDetail: build.mutation({
			query: (body) => {
				return {
					url: "users/get-org-detail",
					method: "post",
					body,
				};
			},
			providesTags: ["orgsDetail"],
		}),
		getOrgs: build.query({
			query: (body) => {
				return {
					url: "users/get-organizations",
					method: "post",
					body,
				};
			},
			providesTags: ["orgs"],
		}),
		addOrg: build.mutation({
			query: (body) => {
				return {
					url: "users/add-organization",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["orgs"],
		}),

		getUserPermissions: build.query({
			query: (body) => {
				return {
					url: "users/get-permissions",
					method: "post",
					body,
				};
			},
		}),

		getAllPermissionSet: build.query({
			query: (body) => {
				return {
					url: "users/get-all-permissionset",
					method: "post",
					body,
				};
			},
		}),
		addRole: build.mutation({
			query: (body) => {
				return {
					url: "users/create-user-role",
					method: "post",
					body,
				};
			},
		}),
		getRoles: build.mutation({
			query: (body) => {
				return {
					url: "users/get-user-roles",
					method: "post",
					body,
				};
			},
		}),
		updateRole: build.mutation({
			query: (body) => {
				return {
					url: "users/update-user-role",
					method: "post",
					body,
				};
			},
		}),
		deleteRole: build.mutation({
			query: (body) => {
				return {
					url: "users/delete-user-role",
					method: "post",
					body,
				};
			},
		}),

		getTheme: build.query({
			query: (body) => {
				return {
					url: "users/get-theme",
					method: "post",
					body,
				};
			},
			providesTags: ["theme"],
		}),

		updateTheme: build.mutation({
			query: (body) => {
				return {
					url: "users/update-theme",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["theme", "orgs"],
		}),
		updateTourStatus: build.mutation({
			query: (body) => {
				return {
					url: "users/update-tour-status",
					method: "post",
					body,
				};
			},
			// invalidatesTags: ["user-detail"],
		}),
        getUsersDashboardStats: build.mutation({
            query: (body) => {
                return {
                    url: "users/dashboard-stats",
                    method: "post",
                    body,
                };
            },
            providesTags: ["dashboard_stats_users"],
        }),
		//
	}),
	overrideExisting: false,
});

// console.log({ userApi });
export const {
	useAddUserMutation,
	useUpdateUserMutation,
	useGetUsersMutation,

	useGetCurrentUserDetailQuery,
	useGetUserDetailMutation,

	useGetThemeQuery,
	useUpdateThemeMutation,

	useGetOrgDetailMutation,
	useAddOrgMutation,
	useGetOrgsQuery,

	useGetUserPermissionsQuery,

	useLoginMutation,
	useChangePasswordMutation,
	useForgotPasswordMutation,
	useUpdatePasswordMutation,

	useAddRoleMutation,
	useGetRolesMutation,
	useUpdateRoleMutation,
	useDeleteRoleMutation,

	useGetAllPermissionSetQuery,
	useUpdateTourStatusMutation,
    useUpdateUserPermissionMutation,
    useGetUsersDashboardStatsMutation,
} = userApi;
